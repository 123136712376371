import { comma, getFormattedTouristStatus } from '@/utils/kiosk';

export const kioskStatusData = {
  columns: [
    { toggle: false, type: 'kioskIndex', title: 'No' },
    { toggle: false, type: 'name', title: '키오스크명' },
    { toggle: false, type: 'bd1Count', title: '지폐1 잔량' },
    { toggle: false, type: 'bd2Count', title: '지폐2 잔량' },
    { toggle: false, type: 'bd3Count', title: '지폐3 잔량' },
    { toggle: false, type: 'hp1Count', title: '동전1 잔량' },
    { toggle: false, type: 'kioskStatus', title: '키오스크 상태' },
    { toggle: false, type: 'sustainableDay', title: '유지가능일' },
  ],
};
export const kioskFranchiseeStatusData = {
  columns: [
    { toggle: false, type: 'no', title: 'No' },
    { toggle: false, type: 'type', title: '구분' },
    { toggle: false, type: 'location', title: '' },
    { toggle: false, type: 'name', title: '상호명' },
  ],
};
export const bdOptions = [
  {
    value: null,
    label: '미사용',
  },
  {
    value: 50000,
    label: '5만원권',
  },
  {
    value: 10000,
    label: '만원권',
  },
  {
    value: 5000,
    label: '5천원권',
  },
  {
    value: 1000,
    label: '천원권',
  },
];
export const hp1Options = [
  {
    value: null,
    label: '미사용',
  },
  {
    value: 500,
    label: '5백원',
  },
];
export const cashMonitoringData = {
  columns: [
    { type: 'bd1', maxValue: 2000 },
    { type: 'bd2', maxValue: 2000 },
    { type: 'bd3', maxValue: 2000 },
    { type: 'hp1', maxValue: 300 },
  ],
};
export const trendInfoDetailData = {
  columns: [
    { title: '1일 평균 소진량', type: 'usagePerDay' },
    { title: '유지 가능일', type: 'sustainableDay' },
    { title: '충전 필요량', type: 'requiredAmount' },
  ],
};
export const formatKioskRefundInfo = (raw, raw2, raw3) => {
  const {
    createdDate,
    departureDate,
    name,
    nation,
    departureStatus,
    passportNumber,
    birthDate,
    chnExchangeRate,
  } = raw;
  const { hospitalName, totalPrice, refund } = raw2;

  return [
    {
      label: '공급일자',
      option: {
        name: 'createdDate',
        value: createdDate.replace('T', ' ').replace(/-/g, '.'),
      },
    },
    {
      label: '병원명',
      option: {
        name: 'hospitalName',
        value: hospitalName || '-',
      },
    },
    {
      label: '판매금액',
      option: {
        name: 'totalPrice',
        value: totalPrice || '-',
      },
    },
    {
      label: nation === 'CHN' ? '환급액 / 중국 위안' : '환급액',
      option: {
        name: 'refund',
        value:
          (nation === 'CHN' && chnExchangeRate
            ? `${comma(refund)}원 / ${(refund / chnExchangeRate).toFixed(2)} ¥`
            : comma(refund) + '원') || '-',
      },
    },
    {
      label: '병원입력 고객 이름',
      option: {
        name: 'name',
        value: name || '-',
      },
    },
    {
      label: '병원입력 생년월일',
      option: {
        name: 'birthDate',
        value: birthDate || '-',
      },
    },
    {
      label: '여권번호',
      option: {
        name: 'passportNumber',
        value: passportNumber || '-',
      },
    },
    {
      label: '국적',
      option: {
        name: 'nation',
        value: raw3?.accountInfo?.isHkgNation ? 'HKG' : nation || '-',
      },
    },
    {
      label: '출국 확인',
      option: {
        name: 'touristStatus',
        value: getFormattedTouristStatus(departureStatus) || '-',
      },
    },
    {
      label: '출국 예정일',
      option: {
        name: 'departureDate',
        value: departureDate ? departureDate : '-',
      },
    },
  ];
};
export const formatMedicalServiceAccount = (raw) => {
  const { card, cash, hospitalName, refund, supplyDate, totalPrice } = raw;
  return [
    [
      {
        label: '병원명',
        placeholder: '-',
        option: {
          name: 'hospitalName',
          value: hospitalName || '-',
        },
      },
      {
        label: '공급 일자',
        placeholder: '-',
        option: {
          name: 'supplyDate',
          value: supplyDate || '-',
        },
      },
    ],
    [
      {
        label: '판매 금액',
        placeholder: '-',
        option: {
          name: 'totalPrice',
          value: totalPrice,
        },
      },
      {
        label: '환급액',
        placeholder: '-',
        option: {
          name: 'refund',
          value: refund || '-',
        },
      },
    ],
    [
      {
        label: '카드 결제액',
        placeholder: '-',
        option: {
          name: 'totalPrice',
          value: card || '-',
        },
      },
      {
        label: '현금 결제액',
        placeholder: '-',
        option: {
          name: 'refund',
          value: cash || '-',
        },
      },
    ],
  ];
};
export const formatMedicalServiceInfo = (raw) => {
  const { details } = raw;
  const newArray = [];
  details.forEach((item) => {
    const { productType, price } = item;
    newArray.push([
      {
        label: '시술 항목',
        option: {
          name: 'productType',
          value: productType,
        },
      },
      {
        label: '시술 금액',
        option: {
          name: 'price',
          value: price,
        },
      },
    ]);
  });
  return newArray;
};

export const kioskWithdrawalStatusTitle = [
  '환급건수',
  '만원권 (장)',
  '천원권 (장)',
  '총액 ',
];

export const kioskWithdrawalStatusField = [
  'withdrawalCount',
  'tenThousand',
  'thousand',
  'totalPrice',
];
