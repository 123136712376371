import { comma } from '@/utils/ktp';
import { getFormattedTouristStatus } from '@/utils/medical';

export const homeColumnList = {
  refund: [
    { type: 'refundIndex', title: 'No' },
    { type: 'createDate', title: '환급일자' },
    { type: 'nation', title: '국적' },
    { type: 'hospitalName', title: '상호명' },
    { type: 'totalPrice', title: '결제금액' },
    { type: 'refund ', title: '환급액' },
    { type: 'refundStatus', title: '환급상태' },
  ],
  kiosk: [
    { type: 'kioskIndex', title: 'No' },
    { type: 'hospitalName', title: '상호명' },
    { type: 'bd1Ccount', title: '지폐1 잔량' },
    { type: 'bd2Ccount', title: '지폐2 잔량' },
    { type: 'bd3Count', title: '지폐3 잔량' },
    { type: 'hp1Count', title: '동전1 잔량' },
    { type: 'kioskStatus', title: '키오스크 상태' },
  ],
  notice: [
    { type: 'noticeIndex', title: 'No' },
    { type: 'title', title: '제목' },
    { type: 'scheduledDate', title: '발행 일자' },
    { type: 'writer', title: '작성자' },
  ],
};

export const hospitalStatusData = {
  hospitalStatusRadio: [
    { value: 'ALL', label: '전체' },
    { value: 'NEW_REGISTRATION', label: '신규 등록' },
    { value: 'DOCUMENT_CHECKING', label: '서류 처리중' },
    { value: 'COMPLETE', label: '가맹 완료' },
  ],
  columns: [
    { toggle: false, type: 'hospitalIndex', title: 'No' },
    { toggle: false, type: 'isRead', title: '' },
    { toggle: false, type: 'signUpDate', title: '가입일' },
    { toggle: false, type: 'hospitalName', title: '상호명' },
    { toggle: false, type: 'paymentType', title: '결제 방식' },
    { toggle: false, type: 'equipmentTypes', title: '보유 기기' },
    { toggle: false, type: 'managerName', title: '대표자 성명' },
    { toggle: false, type: 'businessNumber', title: '사업자등록번호' },
    { toggle: false, type: 'tempExpiredDate', title: '유치기관 만료일' },
    { toggle: false, type: 'isRefundOnce', title: '환급현황' },
    { toggle: false, type: 'detail', title: '상세보기' },
  ],
};
export const signUpTableData = [
  [
    {
      label: '사업자등록번호',
      option: {
        name: 'businessNumber',
        placeholder: "'-' 표시 없이 숫자만 입력",
      },
      required: true,
    },
    {
      label: '비밀번호',
      option: {
        name: 'password',
        type: 'password',
        placeholder: '비밀번호 (4자리 이상)',
      },
      required: true,
    },
  ],
  [
    {
      label: '상호명',
      option: { name: 'hospitalName', type: 'text' },
      required: true,
    },
    {
      label: '대표자명',
      option: { name: 'managerName', type: 'text' },
      required: true,
    },
  ],
  [
    {
      label: '사업장 주소',
      option: { name: 'hospitalAddress', type: 'text' },
      required: true,
    },
    {
      label: '이메일',
      option: {
        name: 'email',
        type: 'email',
        placeholder: '_______ @ ________',
      },
      required: true,
    },
  ],
  [
    {
      label: '휴대 전화번호',
      option: {
        name: 'hospitalPhoneNumber',
        maxLength: 11,
        placeholder: "'-' 표시 없이 숫자만 입력",
      },
      required: true,
    },
    {
      label: '병원 전화번호',
      option: {
        name: 'hospitalTelNumber',
        placeholder: "'-' 표시 없이 숫자만 입력",
      },
      required: true,
    },
  ],
  [
    {
      label: '결제방식',
      selectName: 'paymentType',
      option: {
        name: 'paymentType',
      },
      optionList: [
        { label: '자동 결제', value: 'ORIGINAL' },
        { label: '전액 결제', value: 'DIRECT' },
      ],
    },
    {
      label: '상위 병원 index',
      option: {
        name: 'superiorId',
        placeholder: '숫자만 입력 (가맹전 현황 리스트 No.)',
      },
    },
  ],
  [
    {
      label: '하위 병원 로그인 ID',
      option: {
        name: 'userId',
        type: 'text',
        placeholder: '자유 생성 (영어 혹은 숫자)',
      },
    },
  ],
];

export const applicationTableData = [
  [
    {
      label: '특례의료기관 지정번호',
      option: {
        name: 'taxFreeNumber',
        type: 'text',
      },
    },
    {
      label: '은행명',
      selectName: 'bankName',
      optionList: [
        '은행 선택',
        '농협',
        '신한은행',
        '기업은행',
        '하나은행',
        '우리은행',
        '국민은행',
        'SC제일은행',
        '대구은행',
        '부산은행',
        '광주은행',
        '새마을금고',
        '경남은행',
        '전북은행',
        '제주은행',
        '산업은행',
        '우체국',
        '신협',
        '수협',
        '한국씨티은행',
        '키카오뱅크',
        '토스뱅크',
        '대신증권',
        '메리츠증권',
        '미래에셋증권',
        '삼성증권',
        '신한금융투자',
        'NH 투자증권',
        '저축은행',
        '한국투자증권',
        'SK증권',
      ],
    },
  ],
  [
    { label: '계좌번호', option: { name: 'accountNumber', type: 'text' } },
    {
      label: '자동이체 출금일',
      selectName: 'withdrawalDate',
      optionList: [
        '출금일 선택',
        '5일',
        '10일',
        '15일',
        '20일',
        '25일',
        '30일',
      ],
    },
  ],
  [{ label: '유치기관 만료일', option: { name: 'expiryDate', type: 'date' } }],
];

export const refundStatusData = {
  columns: [
    { toggle: false, type: 'refundIndex', title: 'No' },
    { toggle: false, type: 'createdDate', title: '환급일자' },
    { toggle: false, type: 'hospitalName', title: '상호명' },
    { toggle: false, type: 'nation', title: '국적' },
    { toggle: false, type: 'touristName', title: '고객명' },
    { toggle: false, type: 'totalPrice', title: '판매금액' },
    { toggle: false, type: 'totalRefund', title: '환급액' },
    { toggle: true, type: 'refundStatus', title: '환급 상태' },
    { toggle: true, type: 'departureConfirmDate', title: '출국 확인일' },
    { toggle: true, type: 'paymentStatus', title: '송금 상태' },
    { toggle: true, type: 'departureDateInputStatus', title: '출국 예정일' },
    { toggle: false, type: 'recordReleaseDate', title: '실적 반영일' },
    { toggle: false, type: 'cancelRefund', title: '환급취소' },
    { toggle: false, type: 'ticketImage', title: '항공권 이미지' },
  ],
  refundStatusRadio: [
    { value: '', label: '전체' },
    { value: 'APPROVE', label: '승인' },
    { value: 'CANCEL', label: '취소' },
    {
      value: 'KIOSK_CANCEL',
      label: '키오스크 취소',
    },
  ],
  departureStatusRadio: [
    { value: '', label: '전체' },
    { value: 'DEPARTURE_COMPLETE', label: '확인' },
    { value: 'DEPARTURE_WAIT', label: '미확인' },
  ],
  paymentStatusRadio: [
    {
      value: '',
      label: '전체',
    },
    {
      value: 'INCOMPLETE',
      label: '미완료',
    },
    {
      value: 'COMPLETE',
      label: '완료',
    },
  ],
  departureDateInputStatusRadio: [
    { value: '', label: '전체' },
    { value: 'INPUT', label: '입력' },
    { value: 'ORDERED_INPUT', label: '입력-미래순' },
    { value: 'NOT_INPUT', label: '미입력' },
  ],
};
export const formatMedicalRefundInfo = (raw, raw2, raw3) => {
  const {
    createdDate,
    departureDate,
    name,
    nation,
    departureConfirmDate,
    passportNumber,
    birthDate,
    chnExchangeRate,
  } = raw;
  const { hospitalName, totalPrice, refund } = raw2;

  return [
    {
      label: '공급일자',
      option: {
        name: 'createdDate',
        value: createdDate.replace('T', ' ').replace(/-/g, '.'),
      },
    },
    {
      label: '병원명',
      option: {
        name: 'hospitalName',
        value: hospitalName || '-',
      },
    },
    {
      label: '판매금액',
      option: {
        name: 'totalPrice',
        value: totalPrice || '-',
      },
    },
    {
      label: nation === 'CHN' ? '환급액 / 중국 위안' : '환급액',
      option: {
        name: 'refund',
        value:
          (nation === 'CHN' && chnExchangeRate
            ? `${comma(refund)}원 / ${(refund / chnExchangeRate).toFixed(2)} ¥`
            : comma(refund) + '원') || '-',
      },
    },
    {
      label: '병원입력 고객 이름',
      option: {
        name: 'name',
        value: name || '-',
      },
    },
    {
      label: '병원입력 생년월일',
      option: {
        name: 'birthDate',
        value: birthDate || '-',
      },
    },
    {
      label: '여권번호',
      option: {
        name: 'passportNumber',
        value: passportNumber || '-',
      },
    },
    {
      label: '국적',
      option: {
        name: 'nation',
        value: raw3?.accountInfo?.isHkgNation ? 'HKG' : nation || '-',
      },
    },
    {
      label: '출국 예정일',
      option: {
        name: 'departureDate',
        value: departureDate ? departureDate : '-',
      },
    },
    {
      label: '출국 확인일',
      option: {
        name: 'touristStatus',
        value: departureConfirmDate || '출국 미확인',
      },
    },
  ];
};
export const formatMedicalServiceAccount = (raw) => {
  const { card, cash, hospitalName, refund, supplyDate, totalPrice } = raw;
  return [
    [
      {
        label: '병원명',
        placeholder: '-',
        option: {
          name: 'hospitalName',
          value: hospitalName || '-',
        },
      },
      {
        label: '공급 일자',
        placeholder: '-',
        option: {
          name: 'supplyDate',
          value: supplyDate || '-',
        },
      },
    ],
    [
      {
        label: '판매 금액',
        placeholder: '-',
        option: {
          name: 'totalPrice',
          value: totalPrice,
        },
      },
      {
        label: '환급액',
        placeholder: '-',
        option: {
          name: 'refund',
          value: refund || '-',
        },
      },
    ],
    [
      {
        label: '카드 결제액',
        placeholder: '-',
        option: {
          name: 'totalPrice',
          value: card || '-',
        },
      },
      {
        label: '현금 결제액',
        placeholder: '-',
        option: {
          name: 'refund',
          value: cash || '-',
        },
      },
    ],
  ];
};
export const formatMedicalServiceInfo = (raw) => {
  const { details } = raw;
  const newArray = [];
  details.forEach((item) => {
    const { productType, price } = item;
    newArray.push([
      {
        label: '시술 항목',
        option: {
          name: 'productType',
          value: productType,
        },
      },
      {
        label: '시술 금액',
        option: {
          name: 'price',
          value: price,
        },
      },
    ]);
  });
  return newArray;
};
export const formatRefundPaymentInfo = (raw, nation) => {
  const { accountInfo, email } = raw;
  return [
    [
      {
        label:
          nation === 'CHN'
            ? '성(중국어)'
            : nation === 'JPN'
            ? '성(일본어)'
            : '성',
        placeholder: '성',
        option: {
          name: 'firstName',
          value: !accountInfo ? '' : accountInfo.firstName || '',
        },
      },
      {
        label:
          nation === 'CHN'
            ? '이름(중국어)'
            : nation === 'JPN'
            ? '이름(일본어)'
            : '이름',
        placeholder: '이름',
        option: {
          name: 'lastName',
          value: !accountInfo ? '' : accountInfo.lastName || '',
        },
      },
    ],
    [
      {
        label: '성',
        placeholder: '성',
        option: {
          name: 'firstNameEng',
          value: !accountInfo ? '' : accountInfo.firstNameEng || '',
        },
      },
      {
        label: '이름',
        placeholder: '이름',
        option: {
          name: 'lastNameEng',
          value: !accountInfo ? '' : accountInfo.lastNameEng || '',
        },
      },
    ],
    [
      {
        label: '고객 이메일',
        placeholder: '',
        option: {
          name: 'email',
          value: !email ? '' : email,
        },
      },
      {
        label: '연락처',
        placeholder: '',
        option: {
          name: 'phoneNumber',
          value: !accountInfo ? '' : accountInfo.phoneNumber || '',
        },
      },
    ],
    [
      {
        label: '생일',
        placeholder: '',
        option: {
          name: 'birth',
          value: !accountInfo ? '' : accountInfo.birth || '',
        },
      },
      {
        label: '성별',
        placeholder: '',
        option: {
          name: 'gender',
          value: !accountInfo
            ? ''
            : accountInfo.gender === 'MALE'
            ? '남'
            : accountInfo.gender === 'FEMALE'
            ? '여'
            : accountInfo.gender,
        },
      },
    ],
    [
      {
        label: '공민신분번호 / CURP',
        placeholder: '',
        option: {
          name: 'citizenId',
          value: !accountInfo ? '' : accountInfo.citizenId || '',
        },
      },
      {
        label: '',
        placeholder: '',
        option: {
          name: '',
          value: ' ',
        },
      },
    ],
  ];
};
export const formatRefundAddressInfo = (raw, nation) => {
  const { accountInfo } = raw;
  return [
    [
      {
        label: '은행 이름',

        option: {
          name: 'bank',
          value: !accountInfo ? '' : accountInfo.bank || '',
        },
      },
      {
        label: '계좌 번호 / CLABE',
        option: {
          name: 'bankAccount',
          value: !accountInfo
            ? ''
            : accountInfo.bankAccount || accountInfo.bankAccount || '',
        },
      },
    ],
    [
      {
        label: '계좌 타입',
        option: {
          name: 'accountInfo',
          value: !accountInfo
            ? ''
            : accountInfo.accountInfo || accountInfo.accountType || '',
        },
      },
      {
        label: '예금주',
        option: {
          name: 'accountHolder',
          value: !accountInfo
            ? ''
            : accountInfo.accountHolder || accountInfo.accountHolder || '',
        },
      },
    ],
    [
      {
        label: 'SWIFT/BIC/Branch/BSB/IFSC',
        option: {
          name: 'swiftCode',
          value: !accountInfo
            ? ''
            : accountInfo.swiftCode ||
              accountInfo.branchInformation ||
              accountInfo.bsbNumber ||
              '',
        },
      },
      {
        label: 'Routing/IBAN/Transit',
        option: {
          name: 'routingNumber',
          value: !accountInfo
            ? ''
            : accountInfo.routingNumber ||
              accountInfo.ibanNumber ||
              accountInfo.transitNumber ||
              '',
        },
      },
    ],
    [
      {
        label: "Institution/Recipient's RUT",
        option: {
          name: 'institutionNumber',
          value: !accountInfo ? '' : accountInfo.institutionNumber || '',
        },
      },
      {
        label: '',
        option: {
          name: '',
          value: '',
        },
      },
    ],
  ];
};
export const formatAccountInfo = (raw) => {
  const { accountInfo } = raw;

  return [
    [
      {
        label: '주',
        nationLabel: 'Country/Province/Territory/State/Region',
        placeholder: '',
        option: {
          name: 'stateProvinceRegion',
          value: !accountInfo
            ? ''
            : accountInfo.country && accountInfo.stateProvinceRegion
            ? accountInfo.country + ' / ' + accountInfo.stateProvinceRegion
            : accountInfo.country || accountInfo.stateProvinceRegion || '',
        },
      },
      {
        label: '시',
        nationLabel: 'Cit/Suburb/Regency/Town/District',
        placeholder: '',
        option: {
          name: 'city',
          value: !accountInfo ? '' : accountInfo.city || '',
        },
      },
    ],
    [
      {
        label: '상세 주소 1',
        nationLabel: 'Street Name/Address/Block/Subdistrict',
        option: {
          name: 'address',
          value: !accountInfo ? '' : accountInfo.address || '',
        },
      },
      {
        label: '상세 주소 2',
        nationLabel: 'No. of Street·Building·House·Plot/Village',
        option: {
          name: 'addressDetail',
          value: !accountInfo ? '' : accountInfo.addressDetail || '',
        },
      },
    ],
    [
      {
        label: '상세 주소 3',
        nationLabel: 'Name of Building/APT, UNIT or SUITE',
        placeholder: '',
        option: {
          name: 'addressDetail2',
          value: !accountInfo ? '' : accountInfo.addressDetail2 || '',
        },
      },
      {
        label: 'Post code',
        placeholder: '',
        option: {
          name: 'zipPostalCode',
          value: !accountInfo ? '' : accountInfo.zipPostalCode || '',
        },
      },
    ],
  ];
};
export const formatAccountEngInfo = (raw) => {
  const { accountInfo } = raw;
  // const { stateProvinceRegionEng, cityEng, addressEng, addressDetailEng } =
  //   accountInfo;
  return [
    [
      {
        label: '주(영문)',
        nationLabel: 'Country/Province/Territory/State/Region',
        option: {
          name: 'stateProvinceRegionEng',
          value: !accountInfo ? '' : accountInfo.stateProvinceRegionEng || '',
        },
      },
      {
        label: '시(영문)',
        nationLabel: 'Cit/Suburb/Regency/Town/District',
        placeholder: '',
        option: {
          name: 'cityEng',
          value: !accountInfo ? '' : accountInfo.cityEng || '',
        },
      },
    ],
    [
      {
        label: '상세 주소 1(영문)',
        nationLabel: 'Street Name/Address/Block/Subdistrict',
        option: {
          name: 'addressEng',
          value: !accountInfo ? '' : accountInfo.addressEng || '',
        },
      },
      {
        label: '상세 주소 2(영문)',
        nationLabel: 'No. of Street·Building·House·Plot/Village',
        option: {
          name: 'addressDetailEng',
          value: !accountInfo ? '' : accountInfo.addressDetailEng || '',
        },
      },
    ],
  ];
};
export const pointStatusData = {
  pointStatusRadio: [
    { value: 'ALL', label: '전체' },
    { value: 'POINT_WITHDRAWAL', label: '정산 대기' },
    { value: 'POINT_USED', label: '정산 완료' },
  ],
  columns: [
    { toggle: false, type: 'pointIndex', title: 'No' },
    { toggle: true, type: 'pointStatus', title: '정산 상태' },
    { toggle: false, type: 'requestDate', title: '출금 신청 일자' },
    { toggle: false, type: 'hospitalName', title: '상호명' },
    { toggle: false, type: 'amount', title: '출금 요청 금액' },
  ],
};
export const NoticeData = {
  noticeVisibleRadio: [
    { value: 'ALL', label: '전체' },
    { value: 'TRUE', label: '공개' },
    { value: 'FALSE', label: '비공개' },
  ],
  columns: [
    { toggle: false, type: 'noticeIndex', title: 'No' },
    { toggle: false, type: 'title', title: '제목' },
    { toggle: false, type: 'scheduledDate', title: '발행 일자' },
    { toggle: false, type: 'isFixed', title: '상단 고정 여부' },
    { toggle: true, type: 'visible', title: '노출 상태' },
  ],
};
export const kioskStatusData = {
  columns: [
    { toggle: false, type: 'kioskIndex', title: 'No' },
    { toggle: false, type: 'hospitalName', title: '상호명' },
    { toggle: false, type: 'bd1Count', title: '지폐1 잔량' },
    { toggle: false, type: 'bd2Count', title: '지폐2 잔량' },
    { toggle: false, type: 'bd3Count', title: '지폐3 잔량' },
    { toggle: false, type: 'hp1Count', title: '동전1 잔량' },
    { toggle: false, type: 'kioskStatus', title: '키오스크 상태' },
  ],
};

export const ProductTypeList = [
  { key: 0, name: '쌍커풀수술', value: 0 },
  { key: 1, name: '코성형수술', value: 1 },
  { key: 2, name: '유방수술', value: 2 },
  { key: 3, name: '지방흡입술', value: 3 },
  { key: 4, name: '주름살제거술', value: 4 },
  { key: 5, name: '안면윤곽술', value: 5 },
  { key: 6, name: '치아성형술', value: 6 },
  { key: 7, name: '악안면 교정술', value: 7 },
  { key: 8, name: '색소모반ᆞ주근깨ᆞ흑색점ᆞ기미치료술', value: 8 },
  { key: 9, name: '여드름 치료술', value: 9 },
  { key: 10, name: '제모술', value: 10 },
  { key: 11, name: '탈모치료술, 모발이식술', value: 11 },
  { key: 12, name: '문신술 및 문신제거술, 피어싱', value: 12 },
  { key: 13, name: '지방융해술', value: 13 },
  {
    key: 14,
    name: '피부재생술, 피부미백술, 항노화치료술 및 모공축소술',
    value: 14,
  },
  {
    key: 15,
    name: '기타',
    value: 15,
  },
];
export const remittanceStatusData = {
  columns: [
    { toggle: false, type: 'modifyNeeded', title: '' },
    { toggle: false, type: 'selectBox', title: '' },
    { toggle: false, type: 'remittanceIndex', title: 'No' },
    { toggle: false, type: 'refundDate', title: '환급일자' },
    { toggle: false, type: 'storeName', title: '상호명' },
    { toggle: false, type: 'customerNational', title: '국적' },
    { toggle: false, type: 'sellerName', title: '고객명' },
    { toggle: false, type: 'totalRefund', title: '환급액' },
    { toggle: false, type: 'remittanceInfoDate', title: '정보 입력' },
    { toggle: false, type: 'departureDate', title: '출국 예정일' },
    { toggle: false, type: 'departureConfirmDate', title: '출국 확인일' },
    { toggle: false, type: 'remittanceStatus', title: '송금 상태' },
  ],
};
export const bdOptions = [
  {
    value: null,
    label: '미사용',
  },
  {
    value: 50000,
    label: '5만원권',
  },
  {
    value: 10000,
    label: '만원권',
  },
  {
    value: 5000,
    label: '5천원권',
  },
  {
    value: 1000,
    label: '천원권',
  },
];
export const hp1Options = [
  {
    value: null,
    label: '미사용',
  },
  {
    value: 500,
    label: '5백원',
  },
];
export const managementStatusData = {
  columns: [
    { toggle: false, type: 'managementIndex', title: 'No' },
    { toggle: false, type: 'managementStatus', title: '상태' },
    { toggle: false, type: 'joinDate', title: '가입일' },
    { toggle: false, type: 'storeName', title: '상호명' },
    { toggle: false, type: 'businessNumber', title: '사업자 등록번호' },
    { toggle: false, type: 'expireDate', title: '유치기간 만료일' },
    { toggle: false, type: 'recentRefundDate', title: '최종 환급일' },
    { toggle: false, type: 'actionExpireDate', title: '조치 만료일' },
    { toggle: false, type: 'refundButton', title: '환급 현황' },
    { toggle: false, type: 'detailButton', title: '상세보기' },
  ],
};
