import {
  addDays,
  endOfDay,
  startOfDay,
  startOfMonth,
  endOfMonth,
  addMonths,
  startOfWeek,
  endOfWeek,
  isSameDay,
  format,
} from 'date-fns';
import store from '../store';

export const comma = (x) => {
  return String(x).replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};
// 'APPROVAL' | 'CANCEL' | 'REJECT'
export const formattedRefundStatus = (refundStatus, paymentStatus) => {
  if (refundStatus === 'APPROVAL') {
    return '승인';
  } else if (refundStatus === 'CANCEL') {
    if (paymentStatus === 'KIOSK') {
      return '키오스크 취소';
    }
    return '취소';
  } else if (refundStatus === 'PRE_APPROVAL') {
    return '승인 대기';
  } else if (refundStatus === 'CHECK') {
    return '선별검사';
  } else {
    return '-';
  }
};

// REAPPLIED | REJECTED | WAIT | ACCEPTED | CANCEL | INIT
export const formattedFranchiseeStatus = (franchiseeStatus) => {
  switch (franchiseeStatus) {
    case 'INIT':
      return '미신청';
    case 'WAIT':
      return '대기';
    case 'ACCEPTED':
      return '승인';
    case 'REJECTED':
      return '거절';
    case 'REAPPLIED':
      return '재신청';
    case 'CHECK':
      return '대기';
    case 'COMPLETE':
      return '대기';
    default:
      return 'UNKNOWN';
  }
};

export const formattedpointStatus = (pointStatus) => {
  switch (pointStatus) {
    case 'WITHDRAW':
      return '정산 대기';
    case 'COMPLETE':
      return '정산 완료';
    default:
      return 'UNKNOWN';
  }
};
export const formattedPaymentStatus = (paymentStatus) => {
  switch (paymentStatus) {
    case 'PAYMENT_WAIT':
      return '송금 미완료';
    case 'PAYMENT_IN_PROGRESS':
      return '송금중';
    case 'PAYMENT_COMPLETE':
      return '송금 완료';
    case 'PAYMENT_IN_PROGRESS':
      return '송금중';
    case 'KIOSK':
      return '키오스크 수령';
    default:
      return '-';
  }
};
export const formattedDepartureStatus = (departureStatus) => {
  switch (departureStatus) {
    case 'DEPARTURE_WAIT':
      return '출국 미확인';
    case 'DEPARTURE_COMPLETE':
      return '출국 확인';
    default:
      return '-';
  }
};
export const removeLetter = (text) => {
  return text.toString().replace(/\D/g, '');
};
export const attachedSlash = (text) => {
  return text
    .toString()
    .replace(/[^0-9]/g, '')
    .replace(/^(\d{0,2})(\d{0,2})(\d{0,2})$/g, '$1/$2/$3')
    .replace(/(\/{1,2})$/g, '');
};
export const attachedHyphens = (businessNumber) => {
  return (
    businessNumber.slice(0, 3) +
    '-' +
    businessNumber.slice(3, 5) +
    '-' +
    businessNumber.slice(5)
  );
};

export const getImageName = (url) => {
  const fileName = url.split('/').pop();
  return fileName;
};
export const formatGlobalFranchisee = (raw) => {
  const {
    storeName,
    sellerName,
    businessNumber,
    storeTel,
    email,
    franchiseeStatus,
    storeNumber,
    storeAddressBasic,
    storeAddressDetail,
    businessType,
    createdDate,
  } = raw;
  return [
    [
      {
        label: '상호명',
        option: { name: 'storeName', value: storeName, type: 'text' },
      },
      {
        label: '가맹점 신청상태',
        option: {
          name: franchiseeStatus,
          value: formattedFranchiseeStatus(franchiseeStatus),
          disabled: true,
        },
      },
    ],
    [
      {
        label: '사업자등록번호',
        option: {
          name: 'businessNumber',
          value: businessNumber,
          disabled: true,
        },
      },
      {
        label: '대표자명',
        option: { name: 'sellerName', value: sellerName, type: 'text' },
      },
    ],
    [
      {
        label: '휴대 전화번호',
        option: {
          name: 'storeTel',
          type: 'number',
          maxLength: 11,
          placeholder: "'-' 표시 없이 숫자만 입력",
          value: storeTel,
        },
      },
      {
        label: '매장 전화번호',
        option: {
          name: 'storeNumber',
          type: 'number',
          placeholder: "'-' 표시 없이 숫자만 입력",
          value: storeNumber,
        },
      },
    ],
    [
      {
        label: '사업장 주소',
        option: {
          name: 'storeAddressBasic',
          type: 'text',
          value: storeAddressBasic,
        },
      },
      {
        label: '사업장 상세주소',
        option: {
          name: 'storeAddressDetail',
          type: 'text',
          value: storeAddressDetail,
        },
      },
    ],
    [
      {
        label: '이메일',
        option: {
          name: 'email',
          type: 'email',
          placeholder: '_______ @ ________',
          value: email,
        },
      },
      {
        label: '사업자 분류',
        selectName: 'businessType',
        value: businessType,
        optionList: [
          { label: '사업자 분류 선택', value: '사업자 분류 선택' },
          { label: '개인', value: 'INDIVIDUAL' },
          { label: '법인', value: 'CORPORATE' },
        ],
      },
    ],
    [
      {
        label: '비밀번호',
        option: {
          name: 'password',
          type: 'password',
          value: '******',
        },
      },
      {
        label: '회원가입 날짜',
        option: {
          value: format(new Date(createdDate), 'yyyy년 MM월 dd일 HH시 mm분'),
          disabled: true,
        },
      },
    ],
    [
      {
        label: '종사업자번호',
        option: {
          name: 'subCompanySerial',
          type: 'text',
          placeholder: '종사업자번호',
        },
      },
      {
        label: '',
        option: {},
      },
    ],
  ];
};
export const formatFranchisee = (raw) => {
  const {
    storeName,
    sellerName,
    businessNumber,
    storeTel,
    email,
    isTaxRefundShop,
    franchiseeStatus,
    signboard,
    productCategory,
    storeNumber,
    storeAddressBasic,
    storeAddressDetail,
    createdDate,
    refundStep,
    recommender,
    isOwner,
    businessType,
    posStatus,
    printStatus = 'NONE',
    subCompanySerial,
  } = raw;
  return [
    [
      {
        label: '상호명',
        option: { name: 'storeName', value: storeName, type: 'text' },
      },
      {
        label: '가맹점 신청상태',
        option: {
          name: franchiseeStatus,
          value: formattedFranchiseeStatus(franchiseeStatus),
          disabled: true,
        },
      },
    ],
    [
      {
        label: '대표자명',
        option: { name: 'sellerName', value: sellerName, type: 'text' },
      },
      {
        label: '간판명',
        option: { name: 'signboard', value: signboard, type: 'text' },
      },
    ],
    [
      {
        label: '사업자등록번호',
        option: {
          name: 'businessNumber',
          value: businessNumber,
          disabled: true,
        },
      },
      {
        label: '판매 상품 종목',
        selectName: 'productCategory',
        value: productCategory,
        optionList: [
          '판매상품 종목 선택',
          '의류',
          '신발',
          '잡화',
          '화장품',
          '가전기기',
          '전기 • 전자기기',
          '특산품',
          '호텔 • 숙박',
          '의약품',
          '기타',
        ],
      },
    ],
    [
      {
        label: '휴대 전화번호',
        option: {
          name: 'storeTel',
          type: 'number',
          maxLength: 11,
          placeholder: "'-' 표시 없이 숫자만 입력",
          value: storeTel,
        },
      },
      {
        label: '매장 전화번호',
        option: {
          name: 'storeNumber',
          type: 'number',
          placeholder: "'-' 표시 없이 숫자만 입력",
          value: storeNumber,
        },
      },
    ],
    [
      {
        label: '사업장 주소',
        option: {
          name: 'storeAddressBasic',
          type: 'text',
          value: storeAddressBasic,
        },
      },
      {
        label: '사업장 상세주소',
        option: {
          name: 'storeAddressDetail',
          type: 'text',
          value: storeAddressDetail,
        },
      },
    ],
    [
      {
        label: '이메일',
        option: {
          name: 'email',
          type: 'email',
          placeholder: '_______ @ ________',
          value: email,
        },
      },
      {
        label: '사후면세판매장 가입여부',
        option: { value: isTaxRefundShop, disabled: true },
      },
    ],
    [
      {
        label: '회원가입 날짜',
        option: {
          value: format(new Date(createdDate), 'yyyy년 MM월 dd일 HH시 mm분'),
          disabled: true,
        },
      },
      {
        label: '종사업자 번호',
        option: {
          name: 'subCompanySerial',
          value: subCompanySerial,
          type: 'text',
        },
      },
    ],
    [
      {
        label: '대표자와의 관계',
        selectName: 'isOwner',
        value: isOwner,
        optionList: [
          { label: '대표자와의 관계 선택', value: '대표자와의 관계 선택' },
          { label: '본인', value: true },
          { label: '직원', value: false },
        ],
      },
      {
        label: '사업자 분류',
        selectName: 'businessType',
        value: businessType,
        optionList: [
          { label: '사업자 분류 선택', value: '사업자 분류 선택' },
          { label: '개인', value: 'INDIVIDUAL' },
          { label: '법인', value: 'CORPORATE' },
        ],
      },
    ],
    [
      {
        label: '비밀번호',
        option: {
          name: 'password',
          type: 'password',
          value: '******',
        },
      },
      {
        label: '추천인 코드',
        option: { value: recommender, disabled: true },
      },
    ],
    [
      {
        label: '포스기 연동',
        selectName: 'posStatus',
        value: posStatus,
        optionList: [
          { label: '바코드O', value: 'POS_BARCODE' },
          { label: '바코드X', value: 'POS_ONLY' },
          { label: '연동 안 함', value: 'NONE' },
        ],
      },
      {
        label: '전표 출력',
        selectName: 'printStatus',
        value: printStatus,
        optionList: [
          { label: '사용', value: 'ACTIVE' },
          { label: '사용 안 함', value: 'NONE' },
        ],
      },
    ],
  ];
};
export const formattedStore = (raw) => {
  const { taxFreeStoreNumber } = raw;
  return [
    [
      {
        label: '대리신청 작성서류',
        option: {},
      },
      {
        label: '가맹점 신청 상태',
        option: {},
      },
    ],
    [
      {
        label: '사후면세판매장 지정번호',
        option: {
          name: 'taxFreeStoreNumber',
          type: 'text',
          value: taxFreeStoreNumber,
        },
      },
      {
        label: '',
        option: {},
      },
    ],
  ];
};
export const formattedCMS = (raw) => {
  const { bankAccount } = raw;
  return [
    [
      {
        label: '은행명',
        selectName: 'bankName',
        optionList: [
          '은행 선택',
          '농협',
          '신한은행',
          '기업은행',
          '하나은행',
          '우리은행',
          '국민은행',
          'SC제일은행',
          '대구은행',
          '부산은행',
          '광주은행',
          '새마을금고',
          '경남은행',
          '전북은행',
          '제주은행',
          '산업은행',
          '우체국',
          '신협',
          '수협',
          '한국씨티은행',
          '키카오뱅크',
          '토스뱅크',
          '대신증권',
          '메리츠증권',
          '미래에셋증권',
          '삼성증권',
          '신한금융투자',
          'NH 투자증권',
          '저축은행',
          '한국투자증권',
          'SK증권',
        ],
      },
      {
        label: '계좌번호',
        option: { name: 'bankAccount', type: 'number', value: bankAccount },
      },
    ],
    [
      {
        label: '자동이체 출금일',
        selectName: 'withdrawalDate',
        optionList: [
          '출금일 선택',
          '5일',
          '10일',
          '15일',
          '20일',
          '25일',
          '30일',
        ],
      },
      {},
    ],
  ];
};
export const formatWithdrawal = (data) => {
  const { requestedDate, pointStatus, currentPoint, amount, afterPayment } =
    data;
  return [
    [
      {
        name: '출금 신청 날짜',
        value: format(new Date(requestedDate), 'yyyy년 MM월 dd일 HH시 mm분'),
      },
      {
        name: '정산 상태',
        value: formattedpointStatus(pointStatus),
      },
    ],
    [
      {
        name: '기존 보유 포인트',
        value: Number(currentPoint).toLocaleString() + ' P',
      },
      {
        name: '출금요청 포인트',
        value: Number(amount).toLocaleString() + ' P',
      },
    ],
    [
      {
        name: '정산 후 잔여 포인트',
        value: Number(afterPayment).toLocaleString() + ' P',
      },
      { name: '', value: '' },
    ],
  ];
};
export const formatBankInfo = (data) => {
  const { sellerName, bankName, accountNumber } = data;
  return [
    [
      { name: '예금주명', value: sellerName },
      { name: '은행명', value: bankName },
    ],
    [
      { name: '계좌번호', value: accountNumber },
      { name: '', value: '' },
    ],
  ];
};
export const getByteLength = (string) => {
  return string.replace(/[\0-\x7f]|([0-\u07ff]|(.))/g, '$&$1$2').length;
};
export const getHeaderTitle = (string) => {
  switch (string) {
    case 'ktpTest':
      return 'ktp(테스트)';
    case 'ktpProd':
      return 'ktp(운영)';
    default:
      return string;
  }
};
export const getCurrentMenuArr = (path) => {
  const serverState = store.getState().serverState;
  if (path.match(/card/)) {
    return [
      { title: '홈', path: `/card/${serverState}/home` },
      { title: '가맹점 현황', path: `/card/${serverState}/franchisee-status` },
      { title: '공지사항 관리', path: `/card/${serverState}/refund-status` },
      {
        title: '서버 로그 확인',
        path: `/card/${serverState}/refund-remittance`,
      },
    ];
  } else if (path.match(/qna/)) {
    return [
      {
        title: '매장',
        path: `/qna/${serverState}/ktp`,
      },
      {
        title: '의료',
        path: `/qna/${serverState}/medical`,
      },
    ];
  } else if (path.match(/ktp/)) {
    return [
      { title: '홈', path: `/ktp/${serverState}/home` },
      { title: '가맹점 현황', path: `/ktp/${serverState}/franchisee-status` },
      { title: '환급 현황', path: `/ktp/${serverState}/refund-status` },
      { title: '송금 현황', path: `/ktp/${serverState}/refund-remittance` },
      { title: '관리 대상', path: `/ktp/${serverState}/management` },
      // {
      //   title: '키오스크 상태',
      //   path: `/ktp/${serverState}/kiosk-status`,
      // },
      {
        title: '포인트 정산 관리',
        path: `/ktp/${serverState}/point-withdrawal`,
      },
      {
        title: '푸시 알림 관리',
        path: `/ktp/${serverState}/push-notification`,
      },
      { title: '공지사항 관리', path: `/ktp/${serverState}/notice` },
      { title: '서버 로그 확인', path: `/log-check` },
    ];
  } else if (path.match(/refund\/medical/)) {
    return [
      { title: '홈', path: `/refund/medical/${serverState}/home` },
      {
        title: '가맹점 현황',
        path: `/refund/medical/${serverState}/franchisee-status`,
      },
      {
        title: '환급 현황',
        path: `/refund/medical/${serverState}/refund-status`,
      },
      {
        title: '송금 현황',
        path: `/refund/medical/${serverState}/refund-remittance`,
      },
      {
        title: '관리 대상',
        path: `/refund/medical/${serverState}/management`,
      },
      // {
      //   title: '키오스크 상태',
      //   path: `/refund/medical/${serverState}/kiosk-status`,
      // },
      { title: '공지사항 관리', path: `/refund/medical/${serverState}/notice` },
      { title: '서버 로그 확인', path: `/log-check` },
    ];
  } else if (path.match(/kiosk/)) {
    return [
      { title: '홈', path: `/kiosk/${serverState}/home` },
      { title: '가맹점 현황', path: `/kiosk/${serverState}/franchisee-status` },
      { title: '서버 로그 확인', path: `/log-check` },
    ];
  } else if (path.match(/developer\/refund/)) {
    return [
      { title: '관리코드', path: '/developer/refund' },
      { title: '관리코드 테스트', path: '/developer/refund-test' },
    ];
  } else if (path.match(/developer\/jasypt/)) {
    return [{ title: 'JASYPT', path: '/developer/jasypt' }];
  } else if (path.match(/etc\/successmode/)) {
    return [{ title: '바로가기', path: '/etc/successmode' }];
  } else if (path.match(/etc\/noonch/)) {
    return [
      { title: '호스트 신청', path: '/etc/noonch/host-applicant' },
      { title: '사용자 현황', path: '/etc/noonch/user-info' },
      { title: '통계', path: '/etc/noonch/statistics' },
    ];
  } else if (path.match(/etc\/modugrim/)) {
    return [{ title: '서비스 준비 중', path: '/etc/modugrim' }];
  } else if (path.match(/etc\/hotel/)) {
    return [{ title: '서비스 준비 중', path: '/etc/hotel' }];
  } else if (path.match(/sales/)) {
    return [
      {
        title: '가맹 문의 히스토리',
        path: `/sales/${serverState}/history`,
      },
    ];
  } else {
    return null;
  }
};
export const formatRefundDetail = (raw, isHkg) => {
  const {
    createdDate,
    storeName,
    customerName,
    customerNational,
    totalAmount,
    totalRefund,
    departureStatus,
    departureDate,
    approvalFinishDate,
    passportNumber,
    chnExchangeRate,
    customerEmail,
  } = raw;
  return [
    [
      {
        label: '공급일자',
        option: {
          name: 'createdDate',
          value: createdDate
            ? format(new Date(createdDate), 'yyyy.MM.dd HH:mm:ss')
            : '-',
        },
      },
      {
        label: '상호명',
        option: {
          name: 'storeName',
          value: storeName || '-',
        },
      },
    ],
    [
      {
        label: '판매금액',
        option: {
          name: 'totalAmount',
          value: totalAmount ? comma(totalAmount) + '원' : '-',
        },
      },
      {
        label: `환급액${customerNational === 'CHN' ? ' / 중국 위안' : ''}`,
        option: {
          name: 'totalRefund',
          value: totalRefund
            ? `${comma(totalRefund)}원 ${
                customerNational === 'CHN' && chnExchangeRate
                  ? '/ ' + (totalRefund / chnExchangeRate).toFixed(2) + '¥'
                  : ''
              }`
            : '-',
        },
      },
    ],
    [
      {
        label: '매장입력 고객 이름',
        option: {
          name: 'customerName',
          value: customerName || '-',
        },
      },
      {
        label: '환급 확정일자',
        option: {
          name: 'approvalFinishDate',
          value: approvalFinishDate || '-',
        },
      },
    ],
    [
      {
        label: '여권번호',
        option: {
          name: 'passportNumber',
          value: passportNumber || '-',
        },
      },
      {
        label: '국적',
        option: {
          name: 'nation',
          value: isHkg ? 'HKG' : customerNational || '-',
        },
      },
    ],
    [
      {
        label: '출국 예정일',
        option: {
          name: 'departureDate',
          value: departureDate
            ? format(new Date(departureDate), 'yy년 MM월 dd일')
            : '-',
        },
      },
      {
        label: '출국 확인',
        option: {
          name: 'departureStatus',
          value: departureStatus
            ? formattedDepartureStatus(departureStatus)
            : '-',
        },
      },
    ],
    [
      {
        label: '이메일',
        placeholder: '이메일',
        option: { name: 'email', value: customerEmail || '-' },
      },
      {
        label: '',
        placeholder: '',
        option: { name: '', value: '' },
      },
    ],
  ];
};

export const formatRefundPaymentInfo = (raw, nation) => {
  const {
    firstName,
    firstNameEng,
    lastName,
    lastNameEng,
    phoneNumber,
    birth,
    gender,
    citizenId,
  } = raw;

  return [
    [
      {
        label:
          nation === 'CHN'
            ? '성(중국어)'
            : nation === 'JPN'
            ? '성(일본어)'
            : '성',
        placeholder: '성',
        option: {
          name: 'firstName',
          value: firstName || '',
        },
      },
      {
        label:
          nation === 'CHN'
            ? '이름(중국어)'
            : nation === 'JPN'
            ? '이름(일본어)'
            : '이름',
        placeholder: '이름',
        option: {
          name: 'lastName',
          value: lastName || '',
        },
      },
    ],
    [
      {
        label: '성',
        placeholder: '성',
        option: {
          name: 'firstNameEng',
          value: firstNameEng || '',
        },
      },
      {
        label: '이름',
        placeholder: '이름',
        option: {
          name: 'lastNameEng',
          value: lastNameEng || '',
        },
      },
    ],
    [
      {
        label: '연락처',
        placeholder: '연락처',
        option: { name: 'phoneNumber', value: phoneNumber || '' },
      },
      {
        label: '생일',
        placeholder: '생일',
        option: { name: 'birth', value: birth || '' },
      },
    ],
    [
      {
        label: '성별',
        placeholder: '성별',
        option: {
          name: 'gender',
          value: gender === 'MALE' ? '남' : gender === 'FEMALE' ? '여' : gender,
        },
      },
      {
        label: '공민신분번호 / CURP',
        placeholder: '',
        option: { name: 'citizenId', value: citizenId || '' },
      },
    ],
  ];
};
export const formatRefundAddressInfo = (raw) => {
  const {
    bank,
    bankAccount,
    accountInfo,
    swiftCode,
    branchInformation,
    bsbNumber,
    routingNumber,
    ibanNumber,
    transitNumber,
    institutionNumber,
    accountType,
    accountHolder,
  } = raw;
  return [
    [
      {
        label: '은행 이름',
        option: {
          name: 'bank',
          value: bank || '',
        },
      },
      {
        label: '계좌 번호',
        option: {
          name: 'bankAccount',
          value: bankAccount || '',
        },
      },
    ],
    [
      {
        label: '계좌 타입',
        option: {
          name: accountType ? 'accountType' : 'accountInfo',
          value: accountType || accountInfo || '',
        },
      },
      {
        label: '예금주',
        option: {
          name: 'accountHolder',
          value: accountHolder || '',
        },
      },
    ],
    [
      {
        label: 'SWIFT/BIC/Branch/BSB/IFSC',
        option: {
          name: 'swiftCode',
          value: swiftCode || branchInformation || bsbNumber || '',
        },
      },
      {
        label: 'Routing /IBAN/Transit',
        option: {
          name: 'routingNumber',
          value: routingNumber || ibanNumber || transitNumber || '',
        },
      },
    ],
    [
      {
        label: "Institution/Recipient's RUT",
        option: {
          name: 'institutionNumber',
          value: institutionNumber || '',
        },
      },
      {
        label: '',
        option: {
          name: '',
          value: '',
        },
      },
    ],
  ];
};
export const formatAccountInfo = (raw) => {
  const {
    country,
    stateProvinceRegion,
    city,
    address,
    addressDetail,
    addressDetail2,
    zipPostalCode,
    directInput,
  } = raw;
  return [
    [
      {
        label: '주',
        nationLabel: 'Country/Province/Territory/State/Region',
        option: {
          name: 'stateProvinceRegion',
          value:
            country && stateProvinceRegion
              ? country + ' / ' + stateProvinceRegion
              : country || stateProvinceRegion || '',
        },
      },
      {
        label: '시',
        nationLabel: 'Cit/Suburb/Regency/Town/District',
        placeholder: '',
        option: {
          name: 'city',
          value: directInput || city || '',
        },
      },
    ],
    [
      {
        label: '상세 주소 1',
        nationLabel: 'Street Name/Address/Block/Subdistrict',
        option: {
          name: 'address',
          value: address || '',
        },
      },
      {
        label: '상세 주소 2',
        nationLabel: 'No. of Street·Building·House·Plot/Village',
        option: {
          name: 'addressDetail',
          value: addressDetail || '',
        },
      },
    ],
    [
      {
        label: '상세 주소 3',
        nationLabel: 'Name of Building/APT, UNIT or SUITE',
        placeholder: '',
        option: {
          name: 'addressDetail2',
          value: addressDetail2 || '',
        },
      },
      {
        label: 'Post code',
        placeholder: '',
        option: {
          name: 'zipPostalCode',
          value: zipPostalCode || '',
        },
      },
    ],
  ];
};
export const formatAccountEngInfo = (raw) => {
  const { stateProvinceRegionEng, cityEng, addressEng, addressDetailEng } = raw;

  console.log('row', raw);
  return [
    [
      {
        label: '주(영문)',
        nationLabel: 'Country/Province/Territory/State/Region',
        option: {
          name: 'stateProvinceRegionEng',
          value: stateProvinceRegionEng || '',
        },
      },
      {
        label: '시(영문)',
        nationLabel: 'Cit/Suburb/Regency/Town/District',
        placeholder: '',
        option: {
          name: 'cityEng',
          value: cityEng || '',
        },
      },
    ],
    [
      {
        label: '상세 주소 1(영문)',
        nationLabel: 'Street Name/Address/Block/Subdistrict',
        option: {
          name: 'addressEng',
          value: addressEng || '',
        },
      },
      {
        label: '상세 주소 2(영문)',
        nationLabel: 'No. of Street·Building·House·Plot/Village',
        option: {
          name: 'addressDetailEng',
          value: addressDetailEng || '',
        },
      },
    ],
  ];
};
export const formatDateDot = (date) => {
  if (!date) {
    return;
  }
  const newDate = date.replaceAll('-', '');
  const year = newDate.substring(0, 4);
  const month = newDate.substring(4, 6);
  const day = newDate.substring(6, 8);
  return `${year}.${month}.${day}`;
};

export const getRefundAmount = (price) => {
  if (price < 15000) {
    return 0;
  } else if (price < 30000) {
    return 1000;
  } else if (price < 50000) {
    return 2000;
  } else if (price < 75000) {
    return 3000;
  } else if (price < 100000) {
    return 5000;
  } else if (price < 125000) {
    return 7000;
  } else if (price < 150000) {
    return 8000;
  } else if (price < 175000) {
    return 9000;
  } else if (price < 200000) {
    return 10000;
  } else if (price < 225000) {
    return 12000;
  } else if (price < 250000) {
    return 13000;
  } else if (price < 275000) {
    return 15000;
  } else if (price < 300000) {
    return 17000;
  } else if (price < 325000) {
    return 19000;
  } else if (price < 350000) {
    return 21000;
  } else if (price < 375000) {
    return 23000;
  } else if (price < 400000) {
    return 25000;
  } else if (price < 425000) {
    return 27000;
  } else if (price < 450000) {
    return 28000;
  } else if (price < 475000) {
    return 30000;
  } else if (price < 500000) {
    return 32000;
  } else if (price < 550000) {
    return 35000;
  } else if (price < 600000) {
    return 37000;
  } else if (price < 650000) {
    return 41000;
  } else if (price < 700000) {
    return 45000;
  } else if (price < 750000) {
    return 50000;
  } else if (price < 800000) {
    return 53000;
  } else if (price < 850000) {
    return 57000;
  } else if (price < 900000) {
    return 60000;
  } else if (price < 950000) {
    return 65000;
  } else if (price < 1000000) {
    return 68000;
  } else if (price < 1100000) {
    return 75000;
  } else if (price < 1200000) {
    return 80000;
  } else if (price < 1300000) {
    return 90000;
  } else if (price < 1400000) {
    return 95000;
  } else if (price < 1500000) {
    return 105000;
  } else if (price < 1600000) {
    return 110000;
  } else if (price < 1700000) {
    return 115000;
  } else if (price < 1800000) {
    return 127000;
  } else if (price < 1900000) {
    return 135000;
  } else if (price < 2000000) {
    return 140000;
  } else if (price < 2100000) {
    return 150000;
  } else if (price < 2200000) {
    return 155000;
  } else if (price < 2300000) {
    return 160000;
  } else if (price < 2400000) {
    return 170000;
  } else if (price < 2500000) {
    return 177000;
  } else if (price < 2600000) {
    return 185000;
  } else if (price < 2700000) {
    return 190000;
  } else if (price < 2800000) {
    return 200000;
  } else if (price < 2900000) {
    return 210000;
  } else if (price < 3000000) {
    return 215000;
  } else if (price < 3100000) {
    return 225000;
  } else if (price < 3200000) {
    return 230000;
  } else if (price < 3300000) {
    return 235000;
  } else if (price < 3400000) {
    return 240000;
  } else if (price < 3500000) {
    return 250000;
  } else if (price < 3600000) {
    return 260000;
  } else if (price < 3700000) {
    return 270000;
  } else if (price < 3800000) {
    return 280000;
  } else if (price < 3900000) {
    return 285000;
  } else if (price < 4000000) {
    return 290000;
  } else if (price < 4100000) {
    return 300000;
  } else if (price < 4200000) {
    return 310000;
  } else if (price < 4300000) {
    return 315000;
  } else if (price < 4400000) {
    return 320000;
  } else if (price < 4500000) {
    return 333000;
  } else if (price < 4600000) {
    return 340000;
  } else if (price < 4700000) {
    return 350000;
  } else if (price < 4800000) {
    return 360000;
  } else if (price < 4900000) {
    return 370000;
  } else if (price < 5000000) {
    return 380000;
  } else if (price < 5100000) {
    return 390000;
  } else if (price < 5200000) {
    return 400000;
  } else if (price < 5300000) {
    return 410000;
  } else if (price < 5400000) {
    return 420000;
  } else if (price < 5500000) {
    return 430000;
  } else if (price < 5600000) {
    return 440000;
  } else if (price < 5700000) {
    return 450000;
  } else if (price < 5800000) {
    return 460000;
  } else if (price < 5900000) {
    return 470000;
  } else if (price < 6000000) {
    return 480000;
  } else {
    return Math.ceil(((price / 11) * 0.9) / 1000) * 1000;
  }
};
export const removeComma = (str) => {
  return str.replaceAll(',', '');
};

//react-date-range 커스텀
const defineds = {
  startOfWeek: startOfWeek(new Date()),
  endOfWeek: endOfWeek(new Date()),
  startOfLastWeek: startOfWeek(addDays(new Date(), -7)),
  endOfLastWeek: endOfWeek(addDays(new Date(), -7)),
  startOfToday: startOfDay(new Date()),
  endOfToday: endOfDay(new Date()),
  startOfYesterday: startOfDay(addDays(new Date(), -1)),
  endOfYesterday: endOfDay(addDays(new Date(), -1)),
  startOfMonth: startOfMonth(new Date()),
  endOfMonth: endOfMonth(new Date()),
  startOfLastMonth: startOfMonth(addMonths(new Date(), -1)),
  endOfLastMonth: endOfMonth(addMonths(new Date(), -1)),
};

const staticRangeHandler = {
  range: {},
  isSelected(range) {
    const definedRange = this.range();
    return (
      isSameDay(range.startDate, definedRange.startDate) &&
      isSameDay(range.endDate, definedRange.endDate)
    );
  },
};

export function createStaticRanges(ranges) {
  return ranges.map((range) => ({ ...staticRangeHandler, ...range }));
}

export const defaultStaticRanges = createStaticRanges([
  {
    label: '오늘',
    range: () => ({
      startDate: defineds.startOfToday,
      endDate: defineds.endOfToday,
    }),
  },
  {
    label: '어제',
    range: () => ({
      startDate: defineds.startOfYesterday,
      endDate: defineds.endOfYesterday,
    }),
  },

  {
    label: '이번 주',
    range: () => ({
      startDate: defineds.startOfWeek,
      endDate: defineds.endOfWeek,
    }),
  },
  {
    label: '지난 주',
    range: () => ({
      startDate: defineds.startOfLastWeek,
      endDate: defineds.endOfLastWeek,
    }),
  },
  {
    label: '이번 달',
    range: () => ({
      startDate: defineds.startOfMonth,
      endDate: defineds.endOfMonth,
    }),
  },
  {
    label: '지난 달',
    range: () => ({
      startDate: defineds.startOfLastMonth,
      endDate: defineds.endOfLastMonth,
    }),
  },
  {
    label: '지난 30일',
    range: () => ({
      startDate: addDays(
        defineds.startOfToday,
        (Math.max(Number(30), 1) - 1) * -1,
      ),
      endDate: defineds.endOfToday,
    }),
  },
]);

export const formatReceiptForm = ({
  category,
  purchaseSn,
  refundAfter,
  businessNumber,
  franchiseeName,
  saleDate,
  sellerName,
  storeAddress,
  storeTelNumber,
  taxFreeStoreNumber,
  totalAmount,
  totalRefund,
  expireDate,
  totalVat,
  barcodeS3Path,
  passportNumber,
  accountInfo,
  nation,
}) => {
  const info = JSON.parse(accountInfo);
  return {
    purchaseSn,
    refundAfter,
    barcodeS3Path,
    rowDataArr: [
      {
        title: '지정번호',
        subTitle: 'Certificate No.',
        content: taxFreeStoreNumber,
      },
      {
        title: '판매일',
        subTitle: 'Date of Sale',
        content: saleDate,
      },
      {
        title: '판매자',
        subTitle: 'Retailer',
        content: sellerName,
      },
      { title: '상호', subTitle: 'Name of Company', content: franchiseeName },
      { title: '사업자번호', subTitle: 'Reg.No.', content: businessNumber },
      { title: '주소', subTitle: 'Address', content: storeAddress },
      {
        title: '대표자 / 연락처',
        content: `${sellerName} /    ${storeTelNumber}`,
        isLast: true,
      },
      {
        title: '물품구매내역',
        subTitle: 'Description of Goods',
        content: '',
      },
      {
        title: category,
        subTitle: '단가',
        hasComma: true,
        content: comma(totalAmount),
      },
      { title: '', subTitle: '수량', content: '01' },
      {
        title: '',
        subTitle: '금액',
        hasComma: true,
        content: comma(totalAmount),
      },
      ...(refundAfter
        ? [{ title: '결제유형', subTitle: '', content: '' }]
        : []),
      {
        title: '판매총액',
        subTitle: 'Total Payment',
        content: comma(totalAmount),
        hasComma: true,
      },
      {
        title: '부가가치세',
        subTitle: 'V.A.T',
        content: comma(totalVat),
        hasComma: true,
      },
      ...(refundAfter
        ? [
            {
              title: '개별소비세',
              subTitle: 'I.C.T',
              content: '0',
              hasComma: true,
            },
            {
              title: '교육세',
              subTitle: 'E.T',
              content: '0',
              hasComma: true,
            },
            {
              title: '농어촌특별세',
              subTitle: 'S.T.R.D',
              content: '0',
              hasComma: true,
            },
            {
              title: '새액계',
              subTitle: 'Total Tax',
              content: comma(totalVat),
              hasComma: true,
            },
            {
              title: `제비용`,
            },
            {
              title: `(송금비용)`,
              subTitle: 'Administrative',
            },
            {
              title: '',
              subTitle: 'Charge',
              content: '',
            },
          ]
        : []),
      ...(refundAfter
        ? [
            {
              title: `환급액`,
            },
            {
              title: `(송금세액)`,
              subTitle: 'Net Refund',
            },
            {
              title: '',
              subTitle: 'Value',
              content: comma(totalRefund),
              hasComma: true,
            },
          ]
        : [
            {
              title: '즉시환급상당액',
              subTitle: 'Tax-free Amount',
              hasComma: true,
              content: comma(totalRefund),
            },
          ]),
      ...(!refundAfter
        ? [
            {
              title: '결제금액',
              subTitle: 'Purchase Price',
              hasComma: true,
              content: comma(+totalAmount - +totalRefund),
              isLast: true,
            },
          ]
        : []),
      ...(refundAfter
        ? [
            {
              title: '반출유효기간',
              subTitle: 'Export Expiry',
            },
            {
              title: '',
              subTitle: 'Date',
              content: expireDate,
              isLast: true,
            },
          ]
        : []),

      {
        title: '구매자',
        subTitle: 'Customer in Passport',
      },
      {
        title: '여권번호',
        subTitle: 'Passport No.',
        content: passportNumber,
      },
      {
        title: '성명',
        subTitle: 'Passport Name',
        content: info?.firstNameEng
          ? info?.firstName +
            info?.lastName +
            ' / ' +
            info?.firstNameEng +
            info?.lastNameEng
          : info?.firstName + ' ' + info?.lastName || 'null',
      },
      { title: '국적', subTitle: 'Nationality', content: nation || null },
      { title: '주소', subTitle: 'Address for Refund', content: '' },
      { title: '', subTitle: '', content: '' },
      { title: '서명', subTitle: 'Signature' },
      {
        info: 'I agree above info is used only for Refund',
        isLast: !refundAfter,
      },
      ...(refundAfter
        ? [
            {
              title: '세관반출확인',
              subTitle: 'Stamp For Verification Of Export',
            },
          ]
        : []),
    ],
  };
};

// 키오스크 에러상태 처리
// 에러처리 우선순위: 현금보충 > 에러코드 > 정상
export const determineKioskStatus = (status) => {
  const { bd1Lack, bd2Lack, hp1Lack, bd1Status, bd2Status, hp1Status } = status;
  const result = { cashAlert: '', message: '정상', color: '' };
  // 현금보충 확인
  if (bd1Lack || bd2Lack || hp1Lack) {
    result.cashAlert = '현금 보충 필요';
    result.color = '#FF2D55';
  }

  // 에러코드 확인(지폐)
  const bill = bd1Status ? '천원권' : '만원권';
  const commonErrorMessages = {
    a0: `ER14 접힌지폐)`,
    20: 'ER02 통로걸림',
    30: 'ER03 지폐겹쳐/미방출',
    40: 'ER03 지폐겹쳐/미방출',
    50: 'ER04 길이비정상',
    60: 'ER04 길이비정상',
    70: 'ER05 적재불량',
    10: 'ER06 지폐부족',
    80: 'ER07 기기고장-업체연락필요',
    90: 'ER07 기기고장-업체연락필요',
  };

  const getErrorMessage = (statusCode) => {
    const errorText = commonErrorMessages[statusCode.toString(16)];
    return errorText ? `에러 발생(${bill} ${errorText})` : '';
  };
  const bd1ErrorMessage = getErrorMessage(bd1Status);
  const bd2ErrorMessage = getErrorMessage(bd2Status);
  if (bd1ErrorMessage || bd2ErrorMessage) {
    const errorMessage = bd1ErrorMessage || bd2ErrorMessage;
    result.message = errorMessage;
    result.color = '#FF2D55';
  }
  // 에러코드 확인(동전)
  if (hp1Status) {
    result.message = '에러 발생(ER10 동전 방출기에러)';
    result.color = '#FF2D55';
  }
  // 정상
  return result;
};
export const getKioskDetailCashBalance = () => {
  return {
    columns: [
      { type: 'createdDate', title: '수정 일자' },
      { type: 'managerName', title: '담당자' },
      { type: 'count', title: '만원권' },
      { type: 'count', title: '천원권' },
      { type: 'count', title: '오백원' },
    ],
  };
};
export const getKioskDetailWithdrawalInfo = () => {
  return {
    columns: [
      { type: 'index', title: 'No.' },
      { type: 'createdDate', title: '환급일자' },
      { type: 'refund', title: '환급액' },
      { type: 'count', title: '만원권' },
      { type: 'count', title: '천원권' },
      { type: 'count', title: '오백원' },
    ],
  };
};
export const formatKioskCustomerInfo = (raw) => {
  const { name, nation, passportNumber, birthDate } = raw;
  return [
    {
      label: '이름',
      option: {
        name: 'name',
        value: name,
      },
    },
    {
      label: '여권번호',
      option: {
        name: 'passportNumber',
        value: passportNumber,
      },
    },
    {
      label: '국적',
      option: {
        name: 'nation',
        value: nation,
      },
    },
    {
      label: '생년월일',
      option: {
        name: 'birthDate',
        value: birthDate,
      },
    },
  ];
};
export const formatKioskRefundInfo = (items) => {
  return items.map(
    ({ refundIndex, storeName, createdDate, totalPrice, refund }) => [
      {
        label: 'No',
        option: {
          name: 'refundIndex',
          value: refundIndex.toString(),
        },
      },
      {
        label: '매장명',
        option: {
          name: 'storeName',
          value: storeName,
        },
      },
      {
        label: '판매금액',
        option: {
          name: 'totalPrice',
          value: totalPrice,
        },
      },
      {
        label: '환급액',
        option: {
          name: 'refund',
          value: refund,
        },
      },
      {
        label: '환급일',
        option: {
          name: 'createdDate',
          value: createdDate,
        },
      },
    ],
  );
};

export const formatHomeData = (data) => {
  return {
    refundSummary: data.refundSummary,
    tableData: [
      {
        title: '최근 환급내역',
        value: 'REFUND',
        data: data.latestRefundInfo,
      },
      // {
      //   title: '키오스크 현황',
      //   value: 'KIOSK',
      //   data: data.kioskInfo,
      // },
      {
        title: '주요 공지사항',
        value: 'NOTICE',
        data: data.notice,
      },
    ],
  };
};
export const convertKindOfPosToKorean = (engKindOfPos) => {
  switch (engKindOfPos) {
    case 'GROWING_SALES':
      return '그로잉세일즈';
    case 'OK_POS':
      return 'OK포스';
    //... 앞으로 계약할 POS사 정보
    default:
      return '';
  }
};
export const businessNumberFormatted = (value) => {
  const numericValue = value.replace(/\D/g, '');
  const formattedString = [
    numericValue.slice(0, 3),
    numericValue.slice(3, 5),
    numericValue.slice(5, 10),
  ]
    .filter(Boolean)
    .join('-');

  return formattedString;
};
export const formatFeedbackDetailInfo = (raw) => {
  const {
    storeName,
    storeNumber,
    managerName,
    phoneNumber,
    email,
    address,
    businessNumber,
  } = raw;

  const defaultData = [
    [
      {
        label: '상호명',
        option: { name: 'storeName', value: storeName, type: 'text' },
      },
      {
        label: '세일즈 상호명',
        option: { name: 'salesHospitalName', value: storeNumber, type: 'text' },
      },
    ],
    [
      {
        label: '간판명',
        option: { name: 'storeTitle', value: storeName, type: 'text' },
      },
      {
        label: '',
        option: {},
      },
    ],
    [
      {
        label: '대표자명',
        option: { name: 'managerName', value: managerName, type: 'text' },
      },
      {
        label: '사업자등록번호',
        option: {
          name: 'businessNumber',
          value: businessNumber,
          type: 'text',
          disabled: true,
        },
      },
    ],
    [
      {
        label: '휴대 전화번호',
        option: {
          name: 'hospitalPhoneNumber',
          type: 'number',
          maxLength: 11,
          value: phoneNumber,
        },
      },
      {
        label: '매장 전화번호',
        option: {
          name: 'phoneNumber',
          type: 'number',
          maxLength: 11,
          value: phoneNumber,
        },
      },
    ],
    [
      {
        label: '이메일',
        option: {
          name: 'email',
          type: 'email',
          value: email,
        },
      },
      {
        label: '사업장 주소',
        option: {
          name: 'address',
          type: 'text',
          value: address,
        },
      },
    ],
  ];

  return defaultData;
};
