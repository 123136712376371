import { TableBody } from '@material-ui/core';
import ManagementItem from './ManagementItem';

type ManagementListProps = {
  datas: any[];
  onOpenModal: (e: any, value: number, status: string) => void;
  onOpenDetailModal: (e: any, value: number, status: string) => void;
  onOpenFeedbackModal: (e: any, value: number, status: string) => void;
};

function ManagementList({
  datas,
  onOpenModal,
  onOpenDetailModal,
  onOpenFeedbackModal,
}: ManagementListProps) {
  return (
    <TableBody>
      {datas?.map((data: any, idx: number) => (
        <ManagementItem
          key={idx}
          idx={datas.length - idx}
          data={data}
          datas={datas}
          onOpen={onOpenModal}
          onOpenDetailModal={onOpenDetailModal}
          onOpenFeedbackModal={onOpenFeedbackModal}
        />
      ))}
    </TableBody>
  );
}

export default ManagementList;
