import {
  IGetHospitalStatusPayload,
  IGetHomeDataPayload,
  ISignUpBody,
  IGetRefundListByIndexPayload,
  IUpdateHospitalInfoBody,
  IApplyFranchiseeBody,
  IPointStatusParams,
  IGetNoticeListParams,
  ICreateNoticeBody,
  IUpdateNoticePayload,
  IUpdateMedicalRefundDetailPayload,
  IRefundfilter,
  GetKioskStatusPayload,
  GetKioskStatusResponse,
  GetKioskDetailResponse,
  GetKioskDetailChargingResponse,
  GetKioskDetailWithdrawalResponse,
  GetWithdrawalDetailResponse,
  RefundApprovalBodyType,
  downloadSupplyDocumentPayload,
  IExcelResponse,
  UpdateKioskBillTypePayload,
  UpdateKioskBillTypeBody,
  RemittanceDownloadPayload,
  FeedbackRequest,
  FeedbackResponse,
} from '@/types/api/medical';
import { getFormattedTableData } from '@/utils/common';

import api from '..';
import store from '../../store';
import { QnaDetailResponse } from '@/types/qna.type';

const getTargetServerURL = () => {
  return store.getState().serverState === 'prod'
    ? 'https://erp-test.successmode.co.kr/medical'
    : 'https://erp-test.successmode.co.kr/medical/test';
};
// const TEST_SERVER = 'http://123.142.246.2:20921/medical/test';
export const getHomeData = async (params: IGetHomeDataPayload) => {
  const res = await api.getAxios<any, IGetHomeDataPayload>(
    `${getTargetServerURL()}/home`,
    params,
  );
  return res.data;
};
export const getHospitalStatus = async (params: IGetHospitalStatusPayload) => {
  const res = await api.getAxios<any, any>(
    `${getTargetServerURL()}/hospital`,
    params,
  );

  return {
    ...res.data,
  };
};
// 가맹점 현황 키워드
export const getKeywords = async () => {
  const res = await api.getAxios<any, any>(
    `${getTargetServerURL()}/hospital/keyword`,
  );
  const { hospitalNames, phoneNumbers } = res.data;

  return [
    ...[...new Set(hospitalNames)].map((v) => {
      return { keyword: v };
    }),
    ...[...new Set(phoneNumbers)].map((v) => {
      return { keyword: v };
    }),
  ];
};
// 환급, 송금 현황 키워드
export const getRefundKeywords = async () => {
  const res = await api.getAxios<any, any>(
    `${getTargetServerURL()}/hospital/keyword`,
  );
  const { hospitalNames, phoneNumbers } = res.data;

  return {
    hospitalNames: [...new Set(hospitalNames)].map((v) => {
      return { keyword: v };
    }),
    phoneNumbers: [...new Set(phoneNumbers)].map((v) => {
      return { keyword: v };
    }),
  };
};
export const getHospitalStatusDetail = async (hospitalIndex: number) => {
  const res = await api.getAxios(
    `${getTargetServerURL()}/hospital/detail/${hospitalIndex}`,
  );
  return res.data;
};
export const updateHospitalInfo = async (
  body: IUpdateHospitalInfoBody,
  hospitalIndex: number,
) => {
  const res = await api.patchAxios(
    `${getTargetServerURL()}/hospital/detail/${hospitalIndex}`,
    body,
  );
  return res.data;
};
export const uploadHospitalImage = async (
  formData: FormData,
  hospitalIndex: number,
) => {
  const res = await api.postAxios(
    `${getTargetServerURL()}/hospital/upload/image/${hospitalIndex}`,
    formData,
  );
  return res.data;
};
export const getRefundListByIndex = async ({
  hospitalIndex,
  dateSelector,
  filter,
}: IGetRefundListByIndexPayload) => {
  const queryString =
    dateSelector === 'CUSTOM'
      ? {
          dateFilter: dateSelector,
          startDate: filter.startDate,
          endDate: filter.endDate,
        }
      : { dateFilter: dateSelector };
  const res = await api.getAxios(
    `${getTargetServerURL()}/hospital/refund/detail/${hospitalIndex}`,
    queryString,
  );
  return res.data;
};
export const signUp = async (payload: ISignUpBody) => {
  const res = await api.postAxios(
    `${getTargetServerURL()}/hospital/register`,
    payload,
  );
  return res.data;
};
export const applyFranchisee = async (
  payload: IApplyFranchiseeBody,
  hospitalIndex: number,
) => {
  const res = await api.postAxios(
    `${getTargetServerURL()}/hospital/upload/${hospitalIndex}`,
    payload,
  );
  return res.status;
};
export const getRefundList = async (
  {
    refundStatus,
    departureStatus,
    paymentStatus,
    departureDateInputStatus,
    paymentType,
    startDate,
    endDate,
  }: IRefundfilter,
  page: number,
  keyword: string,
  keywordType: 'PHONE' | 'REFUND' | 'CUSTOMER' | 'STORE' | '',
  size: number,
) => {
  const res = await api.getAxios<any, any>(`${getTargetServerURL()}/refund`, {
    refundStatus,
    departureStatus,
    paymentStatus,
    departureDateInputStatus,
    paymentType,
    startDate,
    endDate,
    keyword,
    keywordType,
    size,
    page,
  });
  return {
    ...res.data,
  };
};
export const getRefundDetail = async (refundIndex: number) => {
  const res = await api.getAxios<any, any>(
    `${getTargetServerURL()}/refund/detail/${refundIndex}`,
  );
  return res.data;
};
export const patchRefundDetailRead = async (refundIndex: number) => {
  const res = await api.patchAxios(
    `${getTargetServerURL()}/transfer/${refundIndex}`,
  );
  return res.data;
};
export const getPointList = async (params: IPointStatusParams) => {
  const res = await api.getAxios<any, any>(
    `${getTargetServerURL()}/point`,
    params,
  );
  return {
    ...res.data,
    totalPages: res.data.totalPages - 1 < 0 ? 0 : res.data.totalPages - 1,
  };
};
export const getNoticeLists = async (params: IGetNoticeListParams) => {
  const res = await api.getAxios<any, any>(
    `${getTargetServerURL()}/notice`,
    params,
  );
  return {
    ...res.data,
  };
};
export const createNotice = async (body: ICreateNoticeBody) => {
  const res = await api.postAxios<any, any, any>(
    `${getTargetServerURL()}/notice`,
    body,
  );
  return res.data;
};
export const uploadImage = async (body: any) => {
  const res = await api.postAxios(`${getTargetServerURL()}/notice/image`, body);
  return res.data;
};
export const getNoticeDetailMedical = async (noticeIndex: number) => {
  const res = await api.getAxios<any, any>(
    `${getTargetServerURL()}/notice/detail/${noticeIndex}`,
  );
  return res.data;
};
export const updateNotice = async ({
  noticeIndex,
  body,
}: IUpdateNoticePayload) => {
  const res = await api.patchAxios(
    `${getTargetServerURL()}/notice/detail/${noticeIndex}`,
    body,
  );
  return res.status;
};
export const deleteNotice = async (noticeIndex: number) => {
  const res = await api.deleteAxios(
    `${getTargetServerURL()}/notice/detail/${noticeIndex}`,
  );
  return res.status;
};
export const updateRefundDetail = async (
  refundIndex: number,
  payload: IUpdateMedicalRefundDetailPayload,
) => {
  const res = await api.patchAxios(
    `${getTargetServerURL()}/refund/detail/${refundIndex}`,
    payload,
  );
  return res.status;
};
// 의료공급확인서 다운로드
export const downloadSupplyDocument = async (
  payload: downloadSupplyDocumentPayload,
) => {
  const res = await api.postAxios<
    IExcelResponse,
    downloadSupplyDocumentPayload,
    null
  >(`${getTargetServerURL()}/excel`, payload);
  return getFormattedTableData(res.data);
};

// 건별 한도조회
export const refundLimit = async (payload: any) => {
  const res = await api.postAxios(
    `${getTargetServerURL()}/refund/limit`,
    payload,
  );
  return res.data;
};
// 가맹점 현황 상세보기 -> 직접 환급하기
export const refundApproval = async (body: RefundApprovalBodyType) => {
  const res = await api.postAxios(`${getTargetServerURL()}/refund`, body);
  return res;
};
// 키오스크 현황
export const getKioskStatus = async (params: GetKioskStatusPayload) => {
  const res = await api.getAxios<GetKioskStatusResponse, GetKioskStatusPayload>(
    `${getTargetServerURL()}/kiosk`,
    params,
  );

  return {
    ...res.data,
    totalPages: res.data.totalPages - 1 < 0 ? 0 : res.data.totalPages - 1,
  };
};
// 키오스크 상세정보
export const getKioskDetail = async (kioskIndex: number) => {
  const res = await api.getAxios<GetKioskDetailResponse, number>(
    `${getTargetServerURL()}/kiosk/detail/${kioskIndex}`,
  );
  return res.data;
};
// 키오스크 상세정보 현금관리내역
export const getKioskDetailDeposit = async (payload: any) => {
  const { kioskIndex, queryString } = payload;
  const res = await api.getAxios<GetKioskDetailChargingResponse, number>(
    `${getTargetServerURL()}/kiosk/deposit/detail/${kioskIndex}`,
    queryString,
  );
  return res.data;
};
// 키오스크 상세정보 환급금 출금내역
export const getKioskDetailWithdrawal = async (payload: any) => {
  const { kioskIndex, queryString } = payload;
  const res = await api.getAxios<GetKioskDetailWithdrawalResponse, number>(
    `${getTargetServerURL()}/kiosk/withdrawal/detail/${kioskIndex}`,
    queryString,
  );
  return res.data;
};
// 키오스크 상세정보 환급금 출금내역 상세보기
export const getWithdrawalDetail = async (refundIndex: number) => {
  const res = await api.getAxios<GetWithdrawalDetailResponse[], number>(
    `${getTargetServerURL()}/kiosk/withdrawal/refunds/${refundIndex}`,
  );
  return res.data;
};
//키오스크 상세보기 현금 권종 설정
export const updateKioskBillType = async ({
  kioskIndex,
  ...body
}: UpdateKioskBillTypePayload) => {
  const res = await api.patchAxios<null, UpdateKioskBillTypeBody, null>(
    `${getTargetServerURL()}/kiosk/billType/${kioskIndex}`,
    body,
  );
  return res.data;
};
// 송금정보리스트
export const getRemittanceList = async (params: any) => {
  const res = await api.getAxios<any, any>(
    `${getTargetServerURL()}/transfer`,
    params,
  );
  return res.data;
};
// 송금정보 엑셀 다운로드
export const downloadRemittanceListExcel = async (params: any) => {
  const res = await api.postAxios(
    `${getTargetServerURL()}/excel/transfer-available`,
    params,
    {
      responseType: 'blob',
    },
  );
  return res.data;
};
// 송금 상태 변경
export const updateMedicalPaymentStatus = async (payload: any) => {
  const res = await api.postAxios(
    `${getTargetServerURL()}/transfer/update/payments`,
    payload,
  );
  return res.status;
};
// 환급취소
export const cancelRefundMedical = async (
  refundIndex: number,
  isKioskWithdrawn: boolean,
) => {
  const res = await api.postAxios(`${getTargetServerURL()}/refund/cancel`, {
    refundId: refundIndex,
    isKioskWithdrawn,
  });
  return res.data;
};
// 모인비즈 확인서 엑셀 다운로드
export const emittanceMoinBizMedicalDownload = async (payload: any) => {
  const { ids, nation } = payload;
  let reqUrl = 'excel/transfer';
  if (nation) {
    reqUrl = `excel/transfer/${nation}`;
  }

  const res = await api.postAxios<Blob, RemittanceDownloadPayload, any>(
    `${getTargetServerURL()}/${reqUrl}`,
    { ids },
    {
      responseType: 'blob',
    },
  );
  return res.data;
};
// 송금 확인서 여러개 다운로드
export const emittanceMedicalDownload = async (payload: any) => {
  const res = await api.postAxios<any, RemittanceDownloadPayload, null>(
    `${getTargetServerURL()}/excel/transfer/images`,
    payload,
  );
  return res.data;
};
export const resetPassword = async (hospitalIndex: number) => {
  const res = await api.patchAxios(
    `${getTargetServerURL()}/hospital/password-reset/${hospitalIndex}`,
  );
  return res;
};
// Qna 목록조회
export const getQnaMedicalList = async (params: any) => {
  const res = await api.getAxios(`${getTargetServerURL()}/question`, params);
  return res.data;
};
// Qna 상세조회
export const getQnaMedicalDetail = async (questionId: number) => {
  const res = await api.getAxios(
    `${getTargetServerURL()}/question/${questionId}`,
  );
  return res.data as QnaDetailResponse;
};
// Qna 답변하기
export const postQnaMedicalAnswer = async (payload: FormData) => {
  const res = await api.postAxios(`${getTargetServerURL()}/question`, payload);
  return res.data;
};
// Qna 읽음처리
export const patchQnaMedicalRead = async (questionId: number) => {
  const res = await api.patchAxios(
    `${getTargetServerURL()}/question/${questionId}`,
  );
  return res.data;
};

// 한국인 티켓 확인
export const getTicket = async (passportNumber: string) => {
  const res = await api.getAxios(
    `${getTargetServerURL()}/admin/receipt/ticket/${passportNumber}`,
  );
  return res.data;
};

// 한국인 출국 완료 확인일자 업데이트 (티켓 확인 시)
export const updateConfirmDate = async (body: { refundId: number }) => {
  const res = await api.patchAxios(
    `${getTargetServerURL()}/refund/confirm/ticket`,
    body,
  );
  return res.data;
};

// 항공권 재확인 요청 메일 보내기
export const sendReuploadMail = async (params: {
  refundId: number;
  redirectUrl: string;
}) => {
  const res = await api.postAxios(
    `${getTargetServerURL()}/refund/reupload/ticket`,
    params,
  );
  return res.data;
};

// 문의 삭제하기
export const deleteQuestion = async (questionId: number) => {
  const res = await api.deleteAxios(
    `${getTargetServerURL()}/question/${questionId}`,
  );
  return res.status;
};

// 문의 숨기기
export const hideQuestion = async (params: { questionIds: number[] }) => {
  const res = await api.patchAxios(
    `${getTargetServerURL()}/question/complete`,
    params,
  );
  return res.status;
};

// 환급내역 완전 삭제
export const deleteMedicalRefund = async (refundId: string) => {
  const res = await api.deleteAxios(
    `${getTargetServerURL()}/refund/delete/${refundId}`,
  );
  return res.status;
};

// 관리 대상 전체 보기
export const getFeedbackList = async (params: FeedbackRequest) => {
  const res = await api.getAxios(`${getTargetServerURL()}/feedback`, params);
  return res.data as FeedbackResponse;
};

// 관리 대상 상세 보기
export const getFeedback = async (id: string) => {
  const res = await api.getAxios(`${getTargetServerURL()}/feedback/${id}`);
  return res.data;
};

// 관리 대상 상세보기 내 추가
export const addFeedback = async (params: {
  storeId: number;
  memo: string;
  feedbackDueDate: string;
  salesId: number | null;
  salesStoreName: string;
}) => {
  const res = await api.postAxios(`${getTargetServerURL()}/feedback`, params);
  return res.data;
};

// 관리 대상 상세보기 내 수정
export const editFeedback = async (params: {
  feedbackId: number;
  memo: string;
  feedbackDueDate: string;
  salesId: number | null;
  salesStoreName: string;
}) => {
  const res = await api.patchAxios(`${getTargetServerURL()}/feedback`, params);
  return res.data;
};
