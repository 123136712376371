import {
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  IconButton,
} from '@material-ui/core';
import { ArrowDropDown, ArrowDropUp } from '@material-ui/icons';

import FlexWrap from '@components/common/FlexWrap';
import styled from 'styled-components';
import Icon from './Icon';
import checkIcon from '@/assets/common/check.png';
import checkedIcon from '@/assets/common/checked.png';
import { Fragment, useState } from 'react';

function DataTable({
  columns,
  dispatch,
  toggle,
  contentComponent,
  remittanceDatas,
  setRemittanceDatas,
  transferView,
}: any) {
  const [isChecked, setIsChecked] = useState(false);

  const onClickAllCheck = () => {
    setIsChecked((prev) => !prev);
    const updatedData = remittanceDatas.map((item: any) => ({
      ...item,
      isChecked: isChecked ? false : true,
    }));
    setRemittanceDatas(updatedData);
  };

  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow
            style={{
              borderBottom: '2px solid #BDBDBD',
            }}
          >
            {columns.map((column: any, idx: number) => {
              return column.toggle ? (
                <TableCell
                  key={column.type}
                  align='left'
                  style={{
                    backgroundColor: '#ffffff',
                    padding: idx === 0 ? '4px 4px 4px 18px' : 4,
                    fontSize: 13,
                  }}
                >
                  <FlexWrap justifyContent='flex-start' alignItems='center'>
                    {column.title}
                    <IconButton
                      disableRipple={true}
                      style={{ backgroundColor: 'transparent' }}
                      size='small'
                      onClick={() => {
                        dispatch({
                          type: 'SET_TOGGLE',
                          payload: toggle === null ? column.type : null,
                        });
                      }}
                    >
                      {toggle === column.type ? (
                        <ArrowDropUp fontSize='medium' />
                      ) : (
                        <ArrowDropDown fontSize='medium' />
                      )}
                    </IconButton>
                  </FlexWrap>
                </TableCell>
              ) : column.type === 'selectBox' ? (
                <TableCell
                  key={column.type}
                  align={column.type === 'customerGender' ? 'center' : 'left'}
                  style={{
                    backgroundColor: '#ffffff',
                    padding: idx === 0 ? '4px 4px 4px 18px' : 4,
                    fontSize: 13,
                  }}
                >
                  <CheckBox onClick={onClickAllCheck}>
                    <Icon
                      imgUrl={isChecked ? checkedIcon : checkIcon}
                      width='20px'
                      height='20px'
                    />
                  </CheckBox>
                </TableCell>
              ) : transferView === 'MAIL_SEND_NEEDED' &&
                column.type === 'isImageUpload' ? (
                <></>
              ) : column.type === 'modifyNeeded' ? (
                <TableCell
                  key={column.type}
                  style={{
                    backgroundColor: '#ffffff',
                    padding: idx === 0 ? '4px 4px 4px 18px' : 4,
                  }}
                />
              ) : (
                <TableCell
                  key={column.type}
                  align={column.type === 'customerGender' ? 'center' : 'left'}
                  style={{
                    backgroundColor: '#ffffff',
                    padding: idx === 0 ? '4px 4px 4px 18px' : 4,
                    fontSize: 13,
                  }}
                >
                  {column.title}
                </TableCell>
              );
            })}
          </TableRow>
        </TableHead>
        {contentComponent}
      </Table>
    </TableContainer>
  );
}

const CheckBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;
export default DataTable;
