import { MouseEvent } from 'react';
import {
  BillType,
  FeedbackDetailInfo,
  FeedbackResponse,
  hospitalStatusType,
  IDetailInfo,
  IFormatedHomeData,
  IGetHomeDataResponse,
  IUploadInfo,
  KioskStatusType,
} from '@/types/api/medical';
import { format, subMonths, subWeeks } from 'date-fns';

export const convertNumberToTwoDigits = (index: number) => {
  return index.toString().length === 1 ? '0' + index : index;
};
export const formatHomeData = (
  data: IGetHomeDataResponse,
): IFormatedHomeData => {
  return {
    refundSummary: data.refundSummary,
    tableData: [
      {
        title: '최근 환급내역',
        value: 'REFUND',
        data: data.latestRefundInfo,
      },
      {
        title: '주요 공지사항',
        value: 'NOTICE',
        data: data.notice,
      },
    ],
  };
};
export const formatHospitalStatus = (value: hospitalStatusType) => {
  switch (value) {
    case 'NEW_REGISTRATION':
      return '신규 등록';
    case 'DOCUMENT_CHECKING':
      return '서류 처리중';
    default:
      return '가맹 완료';
  }
};
export const formatHospitalDetailInfo = (raw: IDetailInfo) => {
  const {
    businessNumber,
    hospitalName,
    hospitalAddress,
    managerName,
    hospitalTelNumber,
    hospitalPhoneNumber,
    email,
    equipmentTypes,
    hospitalImagePath,
    productTypes,
    paymentType,
    isBaroBillRegistered,
  } = raw;

  const lastSection =
    paymentType === 'ORIGINAL'
      ? {
          label: '바로빌 가입 여부',
          option: {
            name: 'isBaroBillRegistered',
            value: `${isBaroBillRegistered ? '가입완료' : '미가입'}`,
            type: 'text',
            disabled: true,
          },
        }
      : {
          label: '',
          option: {
            name: '',
            value: '',
            type: 'text',
          },
        };

  const defaultData = [
    [
      {
        label: '상호명',
        option: { name: 'hospitalName', value: hospitalName, type: 'text' },
      },
      {
        label: '',
        option: {
          name: '',
          value: '',
        },
      },
    ],
    [
      {
        label: '대표자명',
        option: { name: 'managerName', value: managerName, type: 'text' },
      },
      {
        label: '사업자등록번호',
        option: {
          name: 'businessNumber',
          value: businessNumber,
          type: 'text',
          disabled: true,
        },
      },
    ],
    [
      {
        label: '휴대 전화번호',
        option: {
          name: 'hospitalPhoneNumber',
          type: 'number',
          maxLength: 11,
          placeholder: "'-' 표시 없이 숫자만 입력",
          value: hospitalPhoneNumber,
        },
      },
      {
        label: '매장 전화번호',
        option: {
          name: 'hospitalTelNumber',
          type: 'number',
          maxLength: 11,
          placeholder: "'-' 표시 없이 숫자만 입력",
          value: hospitalTelNumber,
        },
      },
    ],
    [
      {
        label: '이메일',
        option: {
          name: 'email',
          type: 'text',
          placeholder: '_______ @ ________',
          value: email,
        },
      },
      {
        label: '사업장 주소',
        option: {
          name: 'hospitalAddress',
          type: 'text',
          value: hospitalAddress,
        },
      },
    ],
    [
      {
        label: '공급시술',
        option: {
          name: 'productTypes',
          value: productTypes,
          label: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15],
          type: 'checkbox',
          isMedicalType: true,
        },
      },
      {
        label: '가맹점 사진',
        option: {
          name: 'hospitalImagePath',
          value: hospitalImagePath,
          isImageUpload: true,
          accept: '.png',
          style: {
            textAlign: 'left',
            cursor: 'pointer',
          },
        },
      },
    ],
    [
      {
        label: '보유 기기 ',
        option: {
          name: 'equipmentTypes',
          value: equipmentTypes,
          list: [
            { label: '키오스크', value: 'KIOSK' },
            { label: '스캐너', value: 'SCANNER' },
          ],
          type: 'checkbox',
          isEquipmentType: true,
        },
      },
      {
        label: '결제방식',
        selectName: 'paymentType',
        value: paymentType,
        option: [
          { label: '자동 결제', value: 'ORIGINAL' },
          { label: '전액 결제', value: 'DIRECT' },
        ],
      },
    ],
    [
      {
        label: '비밀번호',
        option: {
          name: 'password',
          type: 'text',
          placeholder: '',
          value: '******',
        },
      },
      lastSection,
    ],
  ];

  return defaultData;
};
export const formatHospitalFeedbackDetailInfo = (raw: FeedbackDetailInfo) => {
  const {
    storeName,
    storeNumber,
    managerName,
    phoneNumber,
    email,
    address,
    businessNumber,
  } = raw;

  const defaultData = [
    [
      {
        label: '상호명',
        option: { name: 'hospitalName', value: storeName, type: 'text' },
      },
      {
        label: '세일즈 상호명',
        option: { name: 'salesHospitalName', value: storeNumber, type: 'text' },
      },
    ],
    [
      {
        label: '대표자명',
        option: { name: 'managerName', value: managerName, type: 'text' },
      },
      {
        label: '사업자등록번호',
        option: {
          name: 'businessNumber',
          value: businessNumber,
          type: 'text',
          disabled: true,
        },
      },
    ],
    [
      {
        label: '휴대 전화번호',
        option: {
          name: 'hospitalPhoneNumber',
          type: 'number',
          maxLength: 11,
          value: phoneNumber,
        },
      },
      {
        label: '매장 전화번호',
        option: {
          name: 'phoneNumber',
          type: 'number',
          maxLength: 11,
          value: phoneNumber,
        },
      },
    ],
    [
      {
        label: '이메일',
        option: {
          name: 'email',
          type: 'email',
          value: email,
        },
      },
      {
        label: '사업장 주소',
        option: {
          name: 'address',
          type: 'text',
          value: address,
        },
      },
    ],
  ];

  return defaultData;
};
export const formatApplicantInfo = (raw: IUploadInfo) => {
  const { taxFreeNumber, bankName, accountNumber, withdrawalDate, expiryDate } =
    raw;
  return [
    [
      {
        label: '특례의료기관 지정번호',
        option: {
          name: 'taxFreeNumber',
          type: 'text',
          value: taxFreeNumber,
        },
      },
      {
        label: '은행명',
        selectName: 'bankName',
        optionList: [
          '은행 선택',
          '농협',
          '신한은행',
          '기업은행',
          '하나은행',
          '우리은행',
          '국민은행',
          'SC제일은행',
          '대구은행',
          '부산은행',
          '광주은행',
          '새마을금고',
          '경남은행',
          '전북은행',
          '제주은행',
          '산업은행',
          '우체국',
          '신협',
          '수협',
          '한국씨티은행',
          '키카오뱅크',
          '토스뱅크',
          '대신증권',
          '메리츠증권',
          '미래에셋증권',
          '삼성증권',
          '신한금융투자',
          'NH 투자증권',
          '저축은행',
          '한국투자증권',
          'SK증권',
        ],
      },
    ],
    [
      {
        label: '계좌번호',
        option: { name: 'accountNumber', type: 'number', value: accountNumber },
      },
      {
        label: '자동이체 출금일',
        selectName: 'withdrawalDate',
        optionList: [
          '출금일 선택',
          '5일',
          '10일',
          '15일',
          '20일',
          '25일',
          '30일',
        ],
      },
    ],
    [
      {
        label: '유치기관 만료일',
        option: { name: 'expiryDate', type: 'date', value: expiryDate },
      },
    ],
  ];
};

export const getServiceType = (num: number) => {
  return [
    '쌍커풀 수술',
    '코 성형 수술',
    '유방 수술',
    '지방 흡입술',
    '주름살 제거술',
    '안면 윤곽술',
    '치아 성형술',
    '악안면 교정술',
    '색소 모반/주근깨/흑색점/기미 치료술',
    '여드름 치료술',
    '제모술',
    '탈모 치료/모발이식술',
    '문신/문신 제거/피어싱',
    '지방융해술',
    '피부재생/미백/항노화치료/모공 축소술',
    '기타',
  ][num];
};
export const getFormattedDeparture = (departure: boolean) => {
  return departure ? '출국 확인' : '출국 미확인';
};
export const getFormattedTouristStatus = (type: string) => {
  switch (type) {
    case 'DEPARTURE_WAIT':
      return '출국 미확인';
    case 'DEPARTURE_COMPLETE':
      return '출국 확인';
    default:
      return '-';
  }
};
export const getFormattedRefundStatus = (type: string) => {
  switch (type) {
    case 'APPROVE_CONFIRM':
      return '승인';
    case 'CANCEL':
      return '취소';
    case 'APPROVE_WAIT':
      return '승인 대기';
    default:
      return '-';
  }
};
export const getSixMonth = (date: Date) => {
  const currentDate = new Date();
  const sixMonthsAgo = new Date();
  const createdDate = new Date(date);

  sixMonthsAgo.setMonth(currentDate.getMonth() - 6);
  return sixMonthsAgo.getTime() > createdDate.getTime();
};
export const newRegisterHospital = (date: string) => {
  const currentDate = new Date();
  const weekAgoDate = new Date();
  const createdDate = new Date(date);

  weekAgoDate.setDate(currentDate.getDate() - 7);
  return weekAgoDate.getTime() < createdDate.getTime();
};
export const changeBackground = (target: HTMLElement, isDeparture: boolean) => {
  const background = 'rgb(211, 226, 253)';
  let newTarget: HTMLElement = target;
  if (target.tagName === 'IMG') {
    newTarget = target.parentElement as HTMLElement;
  }
  if (isDeparture) {
    newTarget.style.backgroundColor = 'transparent';
    return;
  }
  if (newTarget.style.backgroundColor === background) {
    newTarget.style.backgroundColor = 'transparent';
    return;
  }
  newTarget.style.backgroundColor = background;
};
// 날짜 필터
export const setFilterDate = (dateStatus: string) => {
  const currentDate = new Date();
  switch (dateStatus) {
    case 'ALL':
      return {
        startDate: '2022-01-01',
        endDate: format(currentDate, 'yyyy-MM-dd'),
      };
    case 'WEEK':
      const oneWeekAgo = subWeeks(currentDate, 1);
      return {
        startDate: format(oneWeekAgo, 'yyyy-MM-dd'),
        endDate: format(currentDate, 'yyyy-MM-dd'),
      };
    case 'MONTH':
      const oneMonthAgo = subMonths(currentDate, 1);
      return {
        startDate: format(oneMonthAgo, 'yyyy-MM-dd'),
        endDate: format(currentDate, 'yyyy-MM-dd'),
      };
    case 'THREE_MONTH':
      const threeMonthsAgo = subMonths(currentDate, 3);
      return {
        startDate: format(threeMonthsAgo, 'yyyy-MM-dd'),
        endDate: format(currentDate, 'yyyy-MM-dd'),
      };
    case 'SIX_MONTH':
      const sixMonthsAgo = subMonths(currentDate, 6);
      return {
        startDate: format(sixMonthsAgo, 'yyyy-MM-dd'),
        endDate: format(currentDate, 'yyyy-MM-dd'),
      };
    default:
      return {
        startDate: '2022-01-01',
        endDate: format(currentDate, 'yyyy-MM-dd'),
      };
  }
};

export const refundLimitSaleDate = (date: string) => {
  const newDate = date.slice(0, 10).replaceAll('-', '');
  return newDate + '0000000';
};

// 키오스크 에러상태 처리
// 에러처리 우선순위: 현금보충 > 에러코드 > 정상
export const determineKioskStatus = (
  billType: BillType,
  status: KioskStatusType,
) => {
  const {
    bd1Lack,
    bd2Lack,
    bd3Lack,
    hp1Lack,
    bd1Status,
    bd2Status,
    bd3Status,
    hp1Status,
  } = status;
  const result = { cashAlert: '', message: '정상', color: '' };
  // 현금보충 확인
  if (bd1Lack || bd2Lack || bd3Lack || hp1Lack) {
    result.cashAlert = '현금 보충 필요';
    result.color = '#FF2D55';
  }

  // 에러코드 확인(지폐)
  const bill = formatBillType(
    billType[bd1Status ? 'bd1' : bd2Status ? 'bd2' : 'bd3'],
  );
  const commonErrorMessages: Record<number | string, string> = {
    a0: `ER14 접힌지폐)`,
    20: 'ER02 통로걸림',
    30: 'ER03 지폐겹쳐/미방출',
    40: 'ER03 지폐겹쳐/미방출',
    50: 'ER04 길이비정상',
    60: 'ER04 길이비정상',
    70: 'ER05 적재불량',
    10: 'ER06 지폐부족',
    80: 'ER07 기기고장-업체연락필요',
    90: 'ER07 기기고장-업체연락필요',
  };

  const getErrorMessage = (statusCode: number | string) => {
    const errorText = commonErrorMessages[statusCode.toString(16)];
    return errorText ? `에러 발생(${bill} ${errorText})` : '';
  };
  const bd1ErrorMessage = getErrorMessage(bd1Status);
  const bd2ErrorMessage = getErrorMessage(bd2Status);
  const bd3ErrorMessage = getErrorMessage(bd3Status);
  if (bd1ErrorMessage || bd2ErrorMessage || bd3ErrorMessage) {
    const errorMessage = bd1ErrorMessage || bd2ErrorMessage || bd3ErrorMessage;
    result.message = errorMessage;
    result.color = '#FF2D55';
  }
  // 에러코드 확인(동전)
  if (hp1Status) {
    result.message = '에러 발생(ER10 동전 방출기에러)';
    result.color = '#FF2D55';
  }
  // 정상
  return result;
};
// KOR 국적이고 출국 예정일이 오늘보다 미래
export const isFormattedKOR = (nation: string, date: string) => {
  if (nation !== 'KOR' || !date) return false;
  const today = new Date();
  const todayDate =
    today.getFullYear() * 10000 +
    (today.getMonth() + 1) * 100 +
    today.getDate();
  const currentDate = parseInt(date.replace(/-/g, ''), 10);

  return todayDate < currentDate;
};
// count 3이상이면 '완료' / KOR이면 isFormattedKOR false이면 '메일' / nationList 포함안되면 '메일'
export const isValidMailText = (
  nation: string,
  date: string,
  count: number,
): string => {
  if (count >= 3) return '(완료)';
  if (nation === 'KOR') {
    return !isFormattedKOR(nation, date) ? '(메일)' : '';
  }
  const nationList = [
    'CHN',
    'CHE',
    'PHL',
    'RUS',
    'USA',
    'JPN',
    'THA',
    'AUS',
    'CAN',
    'DEU',
    'TWN',
    'AED',
    'CHL',
    'NZL',
    'IDN',
    'SGP',
    'MNG',
    'ARE',
    'HKG',
  ];
  return nationList.includes(nation) ? '' : '(메일)';
};
export const getKioskDetailCashBalance = (billType: BillType) => {
  return {
    columns: [
      { type: 'createdDate', title: '수정 일자' },
      { type: 'managerName', title: '담당자' },
      { type: 'count', title: `BD1-${formatBillType(billType.bd1)}` },
      { type: 'count', title: `BD2-${formatBillType(billType.bd2)}` },
      { type: 'count', title: `BD3-${formatBillType(billType.bd3)}` },
      { type: 'count', title: `HP1-${formatBillType(billType.hp1)}` },
    ],
  };
};
export const getKioskDetailWithdrawalInfo = (billType: BillType) => {
  return {
    columns: [
      { type: 'index', title: 'No.' },
      { type: 'createdDate', title: '환급일자' },
      { type: 'refund', title: '환급액' },
      { type: 'count', title: `BD1-${formatBillType(billType?.bd1)}` },
      { type: 'count', title: `BD2-${formatBillType(billType?.bd2)}` },
      { type: 'count', title: `BD3-${formatBillType(billType?.bd3)}` },
      { type: 'count', title: `HP1-${formatBillType(billType?.hp1)}` },
    ],
  };
};
export const formatBillType = (price: number | null) => {
  switch (price) {
    case 50000:
      return '5만원권';
    case 10000:
      return '만원권';
    case 5000:
      return '5천원권';
    case 1000:
      return '천원권';
    case 500:
      return '오백원';
    default:
      return '미사용';
  }
};
// 의료 가맹점 신청 지정번호 형식
export const taxFreeNumberFormatted = (value: string) => {
  // 숫자만 추출하여 새로운 문자열 생성
  const numericValue = value.replace(/\D/g, '');
  // 원하는 형식으로 포맷팅
  const formattedString = [
    'M',
    numericValue.slice(0, 4),
    numericValue.slice(4, 6),
    numericValue.slice(6, 8),
    numericValue.slice(8),
  ]
    .filter(Boolean) // 빈 문자열 제거
    .join('-');

  return formattedString;
};
export const businessNumberFormatted = (value: string) => {
  const numericValue = value.replace(/\D/g, '');
  const formattedString = [
    numericValue.slice(0, 3),
    numericValue.slice(3, 5),
    numericValue.slice(5, 10),
  ]
    .filter(Boolean)
    .join('-');

  return formattedString;
};
