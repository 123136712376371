import { removeLetter } from '../../utils/ktp';

import store from '../../store';
import { formatHomeData } from '@/utils/ktp';
import api from '..';

const getTargetServerURL = () => {
  return store.getState().serverState === 'prod'
    ? 'https://erp-test.successmode.co.kr'
    : 'https://erp-test.successmode.co.kr/tpay/test';
};

// http://123.142.246.2:20921/tpay/test
//value가 'all'이 아닌 key값 찾기
const format = (obj) => {
  const keyArray = Object.keys(obj);
  const allKeyName = keyArray.find((key) => obj[key] === 'all');
  return keyArray.indexOf(allKeyName) === 0 ? keyArray[1] : keyArray[0];
};
export const updateFranchiseeInfo = async (payload, franchiseeIndex) => {
  const res = await api.patchAxios(
    `${getTargetServerURL()}/franchisee-applicants/update/${franchiseeIndex}`,
    payload,
  );
  return res.status;
};
export const signIn = async (payload) => {
  const res = await api.postAxios(`${getTargetServerURL()}/login`, payload);
  return res.status;
};
export const signInVerify = async (payload) => {
  const res = await api.postAxios(
    `${getTargetServerURL()}/login/verify`,
    payload,
  );
  return res.data;
};
export const signOut = async () => {
  const res = await api.getAxios(`${getTargetServerURL()}/logout`);
  return res.data;
};
export const signUp = async (payload) => {
  const res = await api.postAxios(`${getTargetServerURL()}/sign-up`, payload);
  return res.data;
};
export const globalSignUp = async (payload) => {
  const res = await api.postAxios(
    `${getTargetServerURL()}/global/sign-up`,
    payload,
  );
  return res.data;
};
export const applyFranchisee = async (payload, franchiseeIndex) => {
  const res = await api.postAxios(
    `${getTargetServerURL()}/franchisee-applicants/store-bank/${franchiseeIndex}`,
    payload,
  );
  return res.status;
};
export const getFilteredFranchiseeLists = async (
  filter,
  page,
  searchKeyword,
) => {
  const { isRead, franchiseeStatus } = filter;
  let res;
  if (isRead === 'all' && franchiseeStatus === 'all') {
    res = await api.getAxios(`${getTargetServerURL()}/franchisee-applicants`, {
      page,
      searchKeyword,
      size: 10,
    });
  } else if (isRead !== 'all' && franchiseeStatus !== 'all') {
    res = await api.getAxios(
      `${getTargetServerURL()}/franchisee-applicants/filter`,
      {
        type: 'both',
        value: franchiseeStatus,
        page,
        size: 10,
        searchKeyword,
      },
    );
  } else {
    const formatedKey = format(filter);
    res = await api.getAxios(
      `${getTargetServerURL()}/franchisee-applicants/filter`,
      {
        type: formatedKey,
        value: filter[formatedKey],
        page,
        size: 10,
        searchKeyword,
      },
    );
  }
  return {
    ...res.data,
  };
};
export const getWithdrawalLists = async (filter, page, searchKeyword) => {
  const res = await api.getAxios(`${getTargetServerURL()}/tpay/points`, {
    ...filter,
    page,
    searchKeyword,
    size: 10,
  });
  return {
    ...res.data,
  };
};
export const getWithdrawalDetail = async (pointsIndex) => {
  const res = await api.getAxios(
    `${getTargetServerURL()}/tpay/points/${pointsIndex}`,
  );
  return res.data;
};
export const updateTpointData = async (pointsIndex, body) => {
  const res = await api.patchAxios(
    `${getTargetServerURL()}/tpay/points/${pointsIndex}`,
    body,
  );
  return res.status;
};

export const getFranchiseeDetail = async (franchiseeIndex) => {
  const res = await api.getAxios(
    `${getTargetServerURL()}/franchisee-applicants/${franchiseeIndex}`,
  );
  return res.data;
};

export const acceptFranchisee = async ({ franchiseeIndex, ...payload }) => {
  const res = await api.putAxios(
    `${getTargetServerURL()}/franchisee-applicants/${franchiseeIndex}`,
    payload,
  );
  return res.data;
};

export const updateRejectMessage = async ({
  franchiseeIndex,
  rejectReason,
}) => {
  const res = await api.patchAxios(
    `${getTargetServerURL()}/franchisee-applicants/${franchiseeIndex}`,
    {
      rejectReason,
    },
  );
  return res.status;
};
export const getRefundList = async (
  {
    refundType,
    refundStatus,
    departureStatus,
    paymentStatus,
    receiptUploadStatus,
    startDate,
    endDate,
  },
  page,
  searchKeyword,
  keywordType,
  size,
) => {
  const res = await api.getAxios(`${getTargetServerURL()}/refunds`, {
    refundStatus,
    refundType,
    departureStatus,
    paymentStatus,
    receiptUploadStatus,
    startDate: removeLetter(startDate),
    endDate: removeLetter(endDate),
    page,
    size,
    searchKeyword,
    keywordType,
  });
  return {
    ...res.data,
  };
};
export const getRefundDetail = async (refundIndex) => {
  const res = await api.getAxios(
    `${getTargetServerURL()}/refunds/detail/${refundIndex}`,
  );
  return res.data;
};
export const updateRefundDetail = async (refundIndex, payload) => {
  const res = await api.patchAxios(
    `${getTargetServerURL()}/refunds/detail/${refundIndex}`,
    payload,
  );
  return res.data;
};

// 정보수정상태 읽음처리
export const patchRefundDetailRead = async (refundIndex) => {
  const res = await api.patchAxios(
    `${getTargetServerURL()}/transfer/${refundIndex}`,
  );
  return res.data;
};

export const refundApproval = async ({ type, body }) => {
  const res = await api.postAxios(
    `${getTargetServerURL()}/refunds/${
      type === 'IMMEDIATE' ? 'immediate' : 'after'
    }`,
    body,
  );
  return res;
};
export const cancelRefund = async (refundIndex) => {
  const res = await api.postAxios(`${getTargetServerURL()}/refunds/cancel`, {
    refundIndex,
  });
  return res.data;
};
export const getRefundListByfranchisee = async (
  franchiseeIndex,
  { startDate, endDate },
) => {
  const res = await api.getAxios(
    `${getTargetServerURL()}/refunds/${franchiseeIndex}`,
    {
      startDate: removeLetter(startDate),
      endDate: removeLetter(endDate),
    },
  );
  return res.data;
};
export const getRefundListByfranchiseePaging = async (
  franchiseeIndex,
  { startDate, endDate },
  { page, size },
) => {
  const res = await api.getAxios(
    `${getTargetServerURL()}/refunds/page/${franchiseeIndex}`,
    {
      startDate: removeLetter(startDate),
      endDate: removeLetter(endDate),
      page,
      size,
      refundStatus: '',
    },
  );
  return res.data;
};

export const updateIsRead = (franchiseeIndex) => {
  api.patchAxios(
    `${getTargetServerURL()}/franchisee-applicants/check/${franchiseeIndex}`,
  );
};
export const getControlCodeList = async () => {
  const res = await api.getAxios(`${getTargetServerURL()}/refund/codes`);
  return res.data;
};

export const applyOrRemoveControlNumber = async (payload) => {
  const res = await api.postAxios(
    `${getTargetServerURL()}/refund/codes`,
    payload,
  );
  return res.data;
};

export const getPushLists = async () => {
  const res = await api.getAxios(`${getTargetServerURL()}/push`);
  return res.data;
};
export const getPushDetail = async (pushIndex) => {
  const res = await api.getAxios(`${getTargetServerURL()}/push/${pushIndex}`);
  return res.data;
};
export const requestPush = async (payload) => {
  const res = await api.postAxios(`${getTargetServerURL()}/push`, payload);
  return res.status;
};
export const getNoticeLists = async () => {
  const res = await api.getAxios(`${getTargetServerURL()}/notice`);
  return res.data;
};
export const createNotice = async (formData) => {
  const res = await api.postAxios(`${getTargetServerURL()}/notice`, formData);
  return res.status;
};
export const updateNotice = async (noticeIndex, formData) => {
  const res = await api.putAxios(
    `${getTargetServerURL()}/notice/${noticeIndex}`,
    formData,
  );
  return res.status;
};
export const getNoticeDetailKtp = async (noticeIndex) => {
  const res = await api.getAxios(
    `${getTargetServerURL()}/notice/${noticeIndex}`,
  );
  return res.data;
};
export const hideOrDisplayNotice = async (noticeIndex, isInvisible) => {
  const res = await api.patchAxios(
    `${getTargetServerURL()}/notice/${noticeIndex}`,
    {
      isInvisible: !isInvisible,
    },
  );
  return res.status;
};
export const deleteNotice = async (noticeIndex) => {
  const res = await api.deleteAxios(
    `${getTargetServerURL()}/notice/${noticeIndex}`,
  );
  return res.status;
};
export const getNoticeIndexList = async () => {
  const res = await api.getAxios(`${getTargetServerURL()}/push/notice`);
  return res.data.pushNoticeResponseList;
};
// 가맹점 현황 키워드
export const getSearchKeywords = async () => {
  const res = await api.getAxios(`${getTargetServerURL()}/keywords`);
  const { phoneNumbers, storeNames } = res.data;
  return [
    ...[...new Set(phoneNumbers)].map((v) => {
      return { keyword: v };
    }),
    ...[...new Set(storeNames)].map((v) => {
      return { keyword: v };
    }),
  ];
};
// 환급, 송금 현황 키워드
export const getRefundSearchKeywords = async () => {
  const res = await api.getAxios(`${getTargetServerURL()}/keywords`);
  const { phoneNumbers, storeNames } = res.data;
  return {
    phoneNumbers: [...new Set(phoneNumbers)].map((v) => {
      return { keyword: v };
    }),
    storeNames: [...new Set(storeNames)].map((v) => {
      return { keyword: v };
    }),
  };
};
export const getSearchPushKeywords = async () => {
  const res = await api.getAxios(`${getTargetServerURL()}/keywords`);
  const {
    searchAllBusinessNumberList,
    searchAllStoreNameList,
    searchAllIndexList,
  } = res.data;
  return [
    ...[...searchAllBusinessNumberList].map((v) => {
      return { keyword: v };
    }),
    ...[...searchAllStoreNameList].map((v) => {
      return { keyword: v };
    }),
    ...[...searchAllIndexList].map((v) => {
      return { keyword: String(v) };
    }),
  ];
};

export const resetPassword = async (franchiseeId) => {
  const res = await api.patchAxios(
    `${getTargetServerURL()}/franchisee/password/reset/${franchiseeId}`,
  );
  return res;
};
export const applyComplete = async (franchiseeIndex) => {
  const res = await api.patchAxios(
    `${getTargetServerURL()}/franchisee-applicants/status-check/${franchiseeIndex}`,
  );
  return res;
};
export const getHomeData = async (params) => {
  const res = await api.getAxios(`${getTargetServerURL()}/ktp/home`, params);
  return formatHomeData(res.data);
};
export const getReceiptData = async ({ refundIndex }) => {
  const res = await api.getAxios(
    `${getTargetServerURL()}/refunds/download/${refundIndex}`,
    null,
  );
  return res.data;
};
// 송금정보리스트
export const getRemittanceList = async (params) => {
  const res = await api.getAxios(`${getTargetServerURL()}/transfer`, params);
  return res.data;
};

// 송금 상태 변경
export const updatePaymentStatus = async (payload) => {
  const res = await api.postAxios(
    `${getTargetServerURL()}/transfer/update/payments`,
    payload,
  );
  return res.status;
};

// 키오스크 현황
export const getKioskStatus = async (params) => {
  const res = await api.getAxios(`${getTargetServerURL()}/kiosk`, params);
  return {
    ...res.data,
    totalPages: res.data.totalPages - 1 < 0 ? 0 : res.data.totalPages - 1,
  };
};
// 키오스크 상세정보
export const getKioskDetail = async (kioskIndex) => {
  const res = await api.getAxios(
    `${getTargetServerURL()}/kiosk/detail/${kioskIndex}`,
  );
  return res.data;
};
// 키오스크 상세정보 현금관리내역
export const getKioskDetailDeposit = async (payload) => {
  const { kioskIndex, queryString } = payload;
  const res = await api.getAxios(
    `${getTargetServerURL()}/kiosk/deposit/detail/${kioskIndex}`,
    queryString,
  );
  return res.data;
};
// 키오스크 상세정보 환급금 출금내역
export const getKioskDetailWithdrawal = async (payload) => {
  const { kioskIndex, queryString } = payload;
  const res = await api.getAxios(
    `${getTargetServerURL()}/kiosk/withdrawal/detail/${kioskIndex}`,
    queryString,
  );
  return res.data;
};
// 키오스크 상세정보 환급금 출금내역 상세보기
export const getKioskWithdrawalDetail = async (kioskWithdrawalIndex) => {
  const res = await api.getAxios(
    `${getTargetServerURL()}/kiosk/withdrawal/${kioskWithdrawalIndex}`,
  );
  return res.data;
};
export const updatePosInfo = async ({ franchiseeIndex, ...body }) => {
  const res = await api.postAxios(
    `${getTargetServerURL()}/franchisee-applicants/${franchiseeIndex}/posinfo`,
    body,
  );
  return res.status;
};
// 모인비즈 확인서 엑셀 다운로드
export const emittanceMoinBizDownload = async (payload) => {
  const { ids, nation } = payload;
  let reqUrl = '/transfer/excel';
  if (nation) {
    reqUrl = `/transfer/excel/${nation}`;
  }

  const res = await api.postAxios(
    `${getTargetServerURL()}${reqUrl}`,
    { ids },
    {
      responseType: 'blob',
    },
  );
  return res.data;
};
// 송금 확인서 여러개 다운로드
export const emittanceDownload = async (payload) => {
  const res = await api.postAxios(
    `${getTargetServerURL()}/transfer/images`,
    payload,
  );
  return res.data;
};
// Qna 목록조회
export const getQnaList = async (params) => {
  const res = await api.getAxios(`${getTargetServerURL()}/question`, params);
  return res.data;
};
// Qna 상세조회
export const getQnaDetail = async (questionId) => {
  const res = await api.getAxios(
    `${getTargetServerURL()}/question/${questionId}`,
  );
  return res.data;
};
// Qna 답변하기
export const postQnaAnswer = async (params) => {
  const res = await api.postAxios(`${getTargetServerURL()}/question`, params);
  return res.data;
};
// Qna 읽음처리
export const patchQnaRead = async (questionId) => {
  const res = await api.patchAxios(
    `${getTargetServerURL()}/question/${questionId}`,
  );
  return res.data;
};
/** 키오스크 환급 취소 */
export const patchKioskCancel = async (payload) => {
  const res = await api.patchAxios(
    `${getTargetServerURL()}/refunds/kiosk-cancel`,
    payload,
  );
  return res.data;
};

// 문의 삭제하기
export const deleteQuestion = async (questionId) => {
  const res = await api.deleteAxios(
    `${getTargetServerURL()}/question/${questionId}`,
  );
  return res.status;
};

// 문의 숨기기
export const hideQuestion = async (params) => {
  const res = await api.patchAxios(
    `${getTargetServerURL()}/question/complete`,
    params,
  );
  return res.status;
};

// 환급내역 완전 삭제
export const deleteRefund = async (refundIndex) => {
  const res = await api.deleteAxios(
    `${getTargetServerURL()}/refunds/delete/${refundIndex}`,
  );
  return res.status;
};

// 관리 대상 전체 보기
export const getFeedbackList = async (params) => {
  const res = await api.getAxios(`${getTargetServerURL()}/feedback`, params);
  return res.data;
};

// 관리 대상 상세 보기
export const getFeedback = async (id) => {
  const res = await api.getAxios(`${getTargetServerURL()}/feedback/${id}`);
  return res.data;
};

// 관리 대상 상세보기 내 추가
export const addFeedback = async (params) => {
  const res = await api.postAxios(`${getTargetServerURL()}/feedback`, params);
  return res.data;
};

// 관리 대상 상세보기 내 수정
export const editFeedback = async (params) => {
  const res = await api.patchAxios(`${getTargetServerURL()}/feedback`, params);
  return res.data;
};
