import FlexWrap from '@/components/common/FlexWrap';
import React from 'react';
import { SubTitle, TransferContainer } from './DetailModalContent';
import { TransferInput } from './RemittanceStatusRadio';

type Props = {
  refundInfo: any;
  isEdit: boolean;
  setData: React.Dispatch<React.SetStateAction<unknown>>;
};

const PAYMENT_WAY_LIST: { name: string; value: 'ORIGINAL' | 'DIRECT' }[] = [
  {
    name: '차감결제',
    value: 'ORIGINAL',
  },
  {
    name: '전액결제',
    value: 'DIRECT',
  },
];

function PaymentWayRadio({ refundInfo, isEdit, setData }: Props) {
  const onChangeNeedFullPayment = (paymentStatus: 'ORIGINAL' | 'DIRECT') => {
    setData((prev: any) => {
      return {
        ...prev,
        refundInfo: {
          ...prev.refundInfo,
          needFullPayment: paymentStatus === 'DIRECT',
        },
      };
    });
  };

  const refundStatus = refundInfo.needFullPayment ? 'DIRECT' : 'ORIGINAL';

  return (
    <FlexWrap alignItems='center' margin='0 0 20px 0'>
      <SubTitle>결제 방식</SubTitle>
      <TransferContainer dir='row' gap='20px'>
        {PAYMENT_WAY_LIST.map((way) => {
          return (
            <FlexWrap>
              <TransferInput
                id={`refund-status-${way.value}-medical`}
                type='radio'
                checked={refundStatus === way.value}
                onChange={() => onChangeNeedFullPayment(way.value)}
                disabled={!isEdit}
                isRadio
              />
              <label htmlFor={`refund-status-${way.value}-medical`}>
                {way.name}
              </label>
            </FlexWrap>
          );
        })}
      </TransferContainer>
    </FlexWrap>
  );
}

export default PaymentWayRadio;
