import React, { Fragment, useEffect, useState } from 'react';
import styled from 'styled-components';
import { Container } from '@material-ui/core';
import Title from '@/components/common/Title';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import useRemittanceReducer from '@/hooks/medical/useRemittanceReducer';
import { remittanceStatusData } from '@/constants/medical';
import DataTable from '@/components/common/DataTable';
import RemittanceStatusList from '@/components/medical/remmitanceStatus/RemittanceStatusList';
import RemittanceFilter from '@/components/common/Remittance/RemittanceFilter';
import {
  getRefundKeywords,
  getRemittanceList,
  patchRefundDetailRead,
} from '@/api/medical';
import DetailModal from '@/components/medical/refundStatus/DetailModal';
import LoadingView from '@/components/common/LoadingView';
import Pagination from '@/components/common/Pagination';
import { useLocation } from 'react-router-dom';
import DetailSearch from '@/components/common/DetailSearch';
import SearchTooltip from '@/components/common/SearchTooltip';

export type DatasType = {
  refundId: number;
  refundDate: string;
  storeName: string;
  nation: string;
  customerName: string;
  refundAmount: number;
  infoTypedDate: string;
  departureDate: string;
  departureStatus: string;
  uploadImagePath: string;
  paymentStatus: string;
  mailSentCount: number;
  accountInfo: string;
  isChecked?: boolean;
};

function HospitalRemittanceStatus() {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const currentPage = searchParams.get('page') || '0';
  const keyword = searchParams.get('search') || '';
  const keywordType =
    (searchParams.get('searchType') as
      | 'PHONE'
      | 'REFUND'
      | 'CUSTOMER'
      | 'STORE') || '';
  const size = searchParams.get('size') || '100';
  const [state, dispatch] = useRemittanceReducer();
  const { filter, selected } = state;
  const { transferView, paymentStatus, orderBy, mailSendCounts } = filter;

  const [totalPage, setTotalPage] = useState(0);
  const [isFirstPage, setIsFirstPage] = useState(false);
  const [isLastPage, setIsLastPage] = useState(false);
  const [remittanceDatas, setRemittanceDatas] = useState<DatasType[]>([]);
  const [selectedDatas, setSelectedDatas] = useState<DatasType[]>([]);

  const [isFiltered, setIsFiltered] = useState(false);
  const [isSelected, setIsSelected] = useState(false);
  const [isFilterModal, setIsFilterModal] = useState(false);
  const [isPaymentModal, setIsPaymentModal] = useState(false);

  const { columns } = remittanceStatusData;
  const queryClient = useQueryClient();
  const { data: keywords } = useQuery('keywords', getRefundKeywords, {
    retry: false,
  });

  const params = {
    transferView,
    paymentStatus,
    orderBy,
    page: currentPage,
    size,
    mailSendCounts,
    keyword,
    keywordType,
  };

  // 송금현황 리스트 요청
  const { data, isLoading } = useQuery(
    ['medicalRemittanceList', params],
    async () => await getRemittanceList(params),
    {
      onSuccess: ({ totalPages, first, last }) => {
        setTotalPage(totalPages);
        setIsFirstPage(first);
        setIsLastPage(last);
      },
      retry: false,
    },
  );

  // 송금현황 데이터에 isChecked 값 추가
  useEffect(() => {
    const newData = data?.content.map((item: any) => ({
      ...item,
      isChecked: false,
    }));
    setRemittanceDatas(newData);
  }, [data]);

  // 송금완료로 변경할 데이터
  useEffect(() => {
    const newValue = remittanceDatas?.filter((item) => item.isChecked);
    newValue?.length > 0 ? setIsSelected(true) : setIsSelected(false);
    setSelectedDatas(newValue);
  }, [remittanceDatas]);

  // 전체보기, 송금가능, 메일발송 이동
  const onClickChangeTransfer = (value: string) => {
    dispatch({
      type: 'SET_TRANSFERVIEW',
      payload: value,
    });
    setIsFiltered(false);
  };

  // 여러개보기
  const onChangeView = (value: number) => {
    dispatch({
      type: 'SET_SIZE',
      payload: value,
    });
  };

  // 필터 적용
  const applyFilter = (
    orderBy: string,
    paymentStatus: string,
    mailCount: string,
  ) => {
    dispatch({
      type: 'SET_FILTER',
      payload: {
        orderBy,
        paymentStatus,
        mailCount,
      },
    });
    setIsFiltered(true);
  };

  // 수정된 항목 읽음처리
  const { mutate: confirmModified } = useMutation(
    (refundIndex: number) => patchRefundDetailRead(refundIndex),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('medicalRemittanceList');
      },
    },
  );

  // 환급상세보기
  const onOpenModal = (
    e: React.MouseEvent<HTMLElement>,
    refundIndex: number,
    modifyNeededStatus: string,
  ) => {
    if (
      (e.target as Element).id.includes('checkBox') ||
      (e.target as Element).tagName === 'IMG'
    )
      return;
    dispatch({
      type: 'SET_SELECTED',
      payload: { refundIndex },
    });
    if (modifyNeededStatus === 'MODIFIED') {
      confirmModified(refundIndex);
    }
  };
  // 환급상세보기 닫기
  const onCloseModal = () => {
    dispatch({ type: 'SET_SELECTED', selected: null });
  };

  return (
    <StyledContainer maxWidth='xl'>
      <Header>
        <Title>의료 송금 현황</Title>
        <SearchTooltip />
      </Header>
      {keywords && <DetailSearch data={keywords} />}
      <RemittanceFilter
        size={size}
        onChangeView={onChangeView}
        currentTransferView={transferView}
        onClickChangeTransfer={onClickChangeTransfer}
        applyFilter={applyFilter}
        isFiltered={isFiltered}
        isSelected={isSelected}
        selectedDatas={selectedDatas}
        isFilterModal={isFilterModal}
        setIsFilterModal={setIsFilterModal}
        isPaymentModal={isPaymentModal}
        setIsPaymentModal={setIsPaymentModal}
        isMedical
        searchParams={params}
      />
      {isLoading ? (
        <LoadingView />
      ) : (
        <Fragment>
          <DataTable
            columns={columns}
            dispatch={dispatch}
            remittanceDatas={remittanceDatas}
            setRemittanceDatas={setRemittanceDatas}
            contentComponent={
              data ? (
                <RemittanceStatusList
                  datas={remittanceDatas}
                  setRemittanceDatas={setRemittanceDatas}
                  onOpenModal={onOpenModal}
                />
              ) : (
                <p>환급 현황이 없습니다.</p>
              )
            }
          />
          <BottomSection>
            {totalPage > 0 && (
              <Pagination
                initialPage={currentPage}
                totalPage={totalPage}
                isLastPage={isLastPage}
                isFirstPage={isFirstPage}
                displaySize={5}
              />
            )}
          </BottomSection>
        </Fragment>
      )}
      {selected?.refundIndex && (
        <DetailModal onClose={onCloseModal} selected={selected} />
      )}
    </StyledContainer>
  );
}

const StyledContainer = styled(Container)`
  display: flex;
  flex-direction: column;
  padding-bottom: 50px;
`;

const BottomSection = styled.div`
  margin: 20px 0;
`;

const Header = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
  justify-content: flex-start;
`;

export default HospitalRemittanceStatus;
