import {
  FormEvent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useForm } from 'react-hook-form';
import styled from 'styled-components';
import { FormField } from '../form/FormField';
import {
  SALES_STATUS_LABEL_LIST,
  SERVICE_TYPE_LABEL_LIST,
  TAX_ENROLLMENT_LABEL_LIST,
  TODO_STATUS_LABEL_LIST,
} from '@/constants/sales/label';

import { useQueryClient } from 'react-query';
import {
  HistoryInformation,
  Inquiry,
  SalesStatus,
  TodoStatus,
} from '@/types/sales.types';
import { addDays, format } from 'date-fns';
import CloseModal from '../confirmModal/CloseModal';
import XIcon from '../../../assets/common/x.png';
import useModal from '@/hooks/sales/useModal';
import StatusLabel from '../StatusLabel';
import FinalModal from './FinalModal';
import DeleteModal from '../confirmModal/DeleteModal';
import {
  useHistoryAdd,
  useHistoryDelete,
  useHistoryModify,
} from '@/hooks/sales/useHistory';
import { useInquiryAdd, useInquiryDelete } from '@/hooks/sales/useInquiry';
import DeleteSalesModal from '../confirmModal/DeleteSalesModal';

interface IFormInput {
  storeName: string;
  image: FormData;
  ownerName: string;
  contactNumber: string;
  email: string;
  address: string;
  addressDetail: string;
  taxEnrollment: string;
  todoStatus: string;
  serviceType: string;
  salesStatus: string;
  firstInquiryDate: string;
  todoDueDate: string | null;
  finalContactDate: string;
  storeImage: File | undefined;
}

type RegisterHistory = {
  history: string;
};

type Props = {
  id?: number;
  info?: HistoryInformation;
  inquiries?: Inquiry[];
  onClose: () => void;
  onSave: (id: number) => void;
};

function ModalContent({ id, info, inquiries, onClose, onSave }: Props) {
  const defaultValues = {
    storeName: info?.storeName || '',
    image: '',
    ownerName: info?.ownerName || '',
    contactNumber: info?.contactNumber || '',
    email: info?.email || '',
    address: info?.address || '',
    addressDetail: info?.addressDetail || '',
    firstInquiryDate:
      info?.firstInquiryDate || format(new Date(), 'yyyy-MM-dd'),
    taxEnrollment: info?.taxEnrollment ? 'ENROLL_YES' : 'ENROLL_NO',
    serviceType: info?.serviceType || '',
    salesStatus: info?.salesStatus || '',
    todoStatus: info?.todoStatus || '',
    todoDueDate: info
      ? info?.todoDueDate
      : format(addDays(new Date(), 7), 'yyyy-MM-dd'),
    finalContactDate: info?.finalContactDate || '',
    storeImage: undefined,
  };
  const {
    register: registerHistory,
    handleSubmit: handleSubmitHistory,
    watch: watchHistory,
    reset: resetHistory,
    formState: { isValid, errors, isDirty, dirtyFields },
    setFocus: setFocusHistory,
    setError: setErrorHistory,
    setValue: setValueHistory,
  } = useForm<IFormInput>({ defaultValues });

  const {
    register: registerInquiryHistory,
    handleSubmit: handleSubmitInquiryHistory,
    watch: watchInquiryHistory,
    reset: resetInquiryHistory,
  } = useForm<RegisterHistory>({ defaultValues: { history: '' } });
  const {
    isOpenModal: isOpenResetModal,
    openModal: openResetModal,
    closeModal: closeResetModal,
  } = useModal();
  const {
    isOpenModal: isOpenFinalModal,
    openModal: openFinalModal,
    closeModal: closeFinalModal,
  } = useModal();
  const {
    isOpenModal: isOpenDeleteHistoryModal,
    openModal: openDeleteHistoryModal,
    closeModal: closeDeleteHistoryModal,
  } = useModal();

  const [selectedIndexToDelete, setSelectedIndexToDeleted] = useState<number>();
  const [imageUpdateStatus, setImageUpdateStatus] = useState<
    'KEEP' | 'UPDATE' | 'DELETE'
  >('KEEP');

  const queryClient = useQueryClient();
  const { mutate: mutateAddHistory } = useHistoryAdd();
  const { mutate: mutateModifyHistory } = useHistoryModify();
  const { mutate: mutateSaveInquiry } = useInquiryAdd();
  const { mutate: mutateDeleteInquiry } = useInquiryDelete();
  const { mutate: mutateDeleteHistory } = useHistoryDelete();

  const formFieldRef = useRef<HTMLDivElement>(null);
  const inquiryListRef = useRef<HTMLUListElement>(null);
  const closeButtonRef = useRef<HTMLButtonElement>(null);

  const getStatusLabel = useCallback((status: string) => {
    const statusLabel = SALES_STATUS_LABEL_LIST.find(
      (option) => option.value === status,
    );

    return (
      <StatusLabel
        backgroundColor={statusLabel?.backgroundColor}
        color={statusLabel?.color}
      >
        {statusLabel?.label}
      </StatusLabel>
    );
  }, []);

  const historyValue = watchInquiryHistory('history');
  const isDisabledInquiryButton = useMemo(() => {
    return !historyValue || historyValue.trim() === '';
  }, [historyValue]);

  const onSaveHistory = (data: any) => {
    const newData = {
      ...data,
      taxEnrollment: data.taxEnrollment === 'ENROLL_YES',
      imageUpdateStatus,
    };
    delete newData.storeImage;
    const requestBlob = new Blob([JSON.stringify(newData)], {
      type: 'application/json',
    });
    const formData = new FormData();
    formData.append('request', requestBlob);
    formData.append('file', data.storeImage || new Blob());

    if (id) {
      mutateModifyHistory(
        {
          id,
          data: formData,
        },
        {
          onSuccess: () => {
            queryClient.invalidateQueries({
              queryKey: 'detailHistory',
            });
            setValueHistory('storeImage', undefined);
            setImageUpdateStatus('KEEP');
          },
        },
      );
    } else {
      mutateAddHistory(
        { data: formData },
        {
          onSuccess: (data) => {
            onSave(data.salesId);
            setImageUpdateStatus('KEEP');
          },
        },
      );
    }
  };

  const onFailToAddHistory = () => {
    setFocusHistory('storeName');
    setErrorHistory('storeName', {
      type: 'manual',
      message: '상호명을 입력해주세요.',
    });
  };

  const onCheckDataChange = () => {
    if (isDirty && Object.keys(dirtyFields).length > 0) {
      openResetModal();
    } else {
      onClose();
    }
  };

  const onCloseSalesModal = () => {
    closeResetModal();
    onClose();
  };

  const onSubmitInquiryHistory = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (id && watchHistory('storeName')) {
      openFinalModal();
    } else {
      onFailToAddHistory();
    }
  };

  const onSaveInquiryHistory = (state: any) => {
    if (id !== undefined) {
      const submitHandler = handleSubmitInquiryHistory((data: any) =>
        onAddInquiryHistory(data, state),
      );
      submitHandler();
    }
    closeFinalModal();
    resetInquiryHistory({ history: '' });
  };

  const onAddInquiryHistory = (data: any, state: any) => {
    if (id) {
      mutateSaveInquiry(
        {
          salesId: id,
          content: data.history,
          salesStatus: state.salesStatus,
        },
        {
          onSuccess: ({ createDate }) => {
            const submitHandlerHistory = handleSubmitHistory((data: any) =>
              onEditHistory({ data, state, createDate }),
            );
            submitHandlerHistory();
            queryClient.invalidateQueries({
              queryKey: 'salesHistory',
            });
          },
        },
      );
    }
  };

  const onEditHistory = ({ data, state, createDate }: any) => {
    if (id) {
      const newData = {
        ...data,
        taxEnrollment: data.taxEnrollment === 'ENROLL_YES',
        salesStatus: state.salesStatus,
        todoStatus: state.todoStatus,
        todoDueDate: state.todoDueDate,
        finalContactDate: createDate,
      };
      delete newData.storeImage;

      const requestBlob = new Blob([JSON.stringify(newData)], {
        type: 'application/json',
      });

      const formData = new FormData();
      formData.append('request', requestBlob);
      formData.append('file', data.storeImage || new Blob());

      mutateModifyHistory(
        { id, data: formData },
        {
          onSuccess: () => {
            queryClient.invalidateQueries({
              queryKey: 'detailHistory',
            });
          },
        },
      );
    }
  };

  const onDeleteInquiry = (id: number) => {
    mutateDeleteInquiry(
      { id },
      {
        onSuccess: () => setSelectedIndexToDeleted(undefined),
      },
    );
  };

  const onDeleteHistory = () => {
    if (id) {
      mutateDeleteHistory(
        { id },
        {
          onSuccess: () => {
            onClose();
            closeDeleteHistoryModal();
          },
        },
      );
    }
  };

  useEffect(() => {
    const close = (e: globalThis.KeyboardEvent) => {
      if (e.key === 'Escape') {
        e.preventDefault();
        onCheckDataChange();
      }
    };
    window.addEventListener('keydown', close);
    return () => window.removeEventListener('keydown', close);
  }, []);

  useEffect(() => {
    resetHistory({
      storeName: info?.storeName || '',
      ownerName: info?.ownerName || '',
      contactNumber: info?.contactNumber || '',
      email: info?.email || '',
      address: info?.address || '',
      addressDetail: info?.addressDetail || '',
      firstInquiryDate:
        info?.firstInquiryDate || format(new Date(), 'yyyy-MM-dd'),
      taxEnrollment: info
        ? info.taxEnrollment
          ? 'ENROLL_YES'
          : 'ENROLL_NO'
        : 'ENROLL_YES',
      serviceType: info?.serviceType || 'MERCHANT',
      salesStatus: info?.salesStatus || 'INQUIRY',
      todoStatus: info?.todoStatus || 'CONTACT',
      todoDueDate:
        info?.todoDueDate || format(addDays(new Date(), 7), 'yyyy-MM-dd'),
      finalContactDate:
        info?.finalContactDate || format(new Date(), 'yyyy-MM-dd'),
    });
  }, [info, resetHistory]);

  useEffect(() => {
    const scrollToBottom = () => {
      const container = inquiryListRef.current;
      if (container) {
        container.scrollTop = container.scrollHeight;
      }
    };

    scrollToBottom();
  }, [inquiries]);

  const status = watchHistory('salesStatus');
  useEffect(
    function resetDueDateOnCompletion() {
      if (status === 'SALES_SUCCESS' || status === 'SALES_FAILED') {
        setValueHistory('todoDueDate', null);
      }
    },
    [status, setValueHistory],
  );

  return (
    <>
      <Popover>
        <CloseButton onClick={onCheckDataChange}>
          <img src={XIcon} alt='히스토리 상세보기 닫기' width={24} />
        </CloseButton>
        <FranchiseeHistory>
          <Title>가맹점 정보</Title>
          <Form onSubmit={handleSubmitHistory(onSaveHistory)}>
            <FormFieldWrapper ref={formFieldRef}>
              <FormField.TextInput
                label='상호명'
                register={registerHistory('storeName', {
                  required: true,
                })}
                placeholder='상호명을 입력해주세요.'
                required
                error={!!errors.storeName}
              />
              <FormField.Image
                label='이미지'
                register={registerHistory('storeImage')}
                watch={watchHistory}
                placeholder='이미지를 첨부해주세요.'
                imagePath={info?.storeImagePath}
                imageName={info?.storeImageName}
                onChangeStatus={setImageUpdateStatus}
              />
              <FormField.TextInput
                label='대표자명'
                register={registerHistory('ownerName')}
                placeholder='대표자명을 입력해주세요.'
              />
              <FormField.TextInput
                label='연락처'
                register={registerHistory('contactNumber')}
                placeholder='매장 연락처를 입력해주세요.'
              />
              <FormField.TextInput
                label='이메일'
                register={registerHistory('email')}
                placeholder='이메일을 입력해주세요.'
              />
              <FormField.Address
                label='주소'
                register={registerHistory('address', {})}
                watch={watchHistory}
              />
              {watchHistory('address') && (
                <FormField.TextInput
                  label='상세주소'
                  register={registerHistory('addressDetail', {})}
                  placeholder='상세 주소를 입력해주세요.'
                />
              )}
              <FormField.DatePicker
                register={registerHistory('firstInquiryDate')}
                label='첫 문의'
                watch={watchHistory}
              />
              <FormField.Dropdown
                register={registerHistory('taxEnrollment')}
                label='유치기관 등록여부'
                options={TAX_ENROLLMENT_LABEL_LIST}
                container={formFieldRef}
                watch={watchHistory}
              />
              <FormField.Dropdown
                register={registerHistory('serviceType')}
                label='구분'
                options={SERVICE_TYPE_LABEL_LIST}
                container={formFieldRef}
                watch={watchHistory}
              />
              <FormField.Dropdown
                register={registerHistory('salesStatus')}
                label='상태'
                options={SALES_STATUS_LABEL_LIST}
                container={formFieldRef}
                watch={watchHistory}
              />
              <FormField.Dropdown
                register={registerHistory('todoStatus')}
                label='Do List'
                options={TODO_STATUS_LABEL_LIST}
                container={formFieldRef}
                watch={watchHistory}
              />
              <FormField.DatePicker
                register={registerHistory('todoDueDate')}
                label='Do List 기한'
                watch={watchHistory}
              />
              <FormField.DatePicker
                register={registerHistory('finalContactDate')}
                label='최종 연락일'
                watch={watchHistory}
              />
            </FormFieldWrapper>
            <ButtonWrapper>
              <Button
                type='button'
                onClick={id ? openDeleteHistoryModal : onClose}
                ref={closeButtonRef}
              >
                {id ? '삭제하기' : '닫기'}
              </Button>
              <Button color='blue' type='submit' disabled={!isValid}>
                저장하기
              </Button>
            </ButtonWrapper>
          </Form>
        </FranchiseeHistory>
        <InquiryHistoryContainer>
          <Title>문의 히스토리</Title>
          <Container>
            <InquiryList ref={inquiryListRef}>
              {inquiries?.map((inquiry) => (
                <InquiryItem key={inquiry.createDate}>
                  <Header>
                    <InquiryHistory>
                      {format(
                        new Date(inquiry.createDate),
                        'yyyy.MM.dd HH:mm:ss',
                      )}
                      - {inquiry.contactManager}
                    </InquiryHistory>
                    <DeleteButton
                      onClick={() =>
                        setSelectedIndexToDeleted(inquiry.inquiryId)
                      }
                    >
                      <img src={XIcon} alt='히스토리 삭제하기' width={18} />
                    </DeleteButton>
                  </Header>
                  <Content>
                    <StatusLabel>
                      {getStatusLabel(inquiry.salesStatus)}
                    </StatusLabel>
                    <Text>{inquiry.content}</Text>
                  </Content>
                </InquiryItem>
              ))}
            </InquiryList>
          </Container>
          <HistoryInput onSubmit={onSubmitInquiryHistory}>
            <TextArea
              placeholder='문의 내용을 입력해주세요.'
              {...registerInquiryHistory('history')}
            />
            <HistoryButton type='submit' disabled={isDisabledInquiryButton}>
              남기기
            </HistoryButton>
          </HistoryInput>
        </InquiryHistoryContainer>
      </Popover>
      {isOpenResetModal && (
        <CloseModal onKeep={closeResetModal} onClose={onCloseSalesModal} />
      )}
      {isOpenFinalModal && id && (
        <FinalModal
          onClose={closeFinalModal}
          onSave={onSaveInquiryHistory}
          defaultValues={{
            salesStatus: watchHistory('salesStatus') as SalesStatus,
            todoStatus: watchHistory('todoStatus') as TodoStatus,
            todoDueDate: watchHistory('todoDueDate') as string,
          }}
        />
      )}
      {isOpenDeleteHistoryModal && id && (
        <DeleteSalesModal
          isOpen={isOpenDeleteHistoryModal}
          onCancel={closeDeleteHistoryModal}
          onDelete={onDeleteHistory}
        />
      )}
      {selectedIndexToDelete && (
        <DeleteModal
          onKeep={() => setSelectedIndexToDeleted(undefined)}
          onDelete={() => onDeleteInquiry(selectedIndexToDelete)}
          title='삭제하시겠습니까?'
          message='삭제한 히스토리는 더이상 복구되지 않습니다.'
        />
      )}
    </>
  );
}

const Popover = styled.aside`
  display: flex;
  gap: 36px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 36px 24px;
  width: 90vw;
  height: 90vh;
  min-width: 920px;
  max-width: 1420px;
  max-height: 979px;
  background-color: #ffffff;
  border-radius: 20px;
  box-shadow: 0px 0px 30px 0px #00000014;
  z-index: 40;
`;

const FranchiseeHistory = styled.div`
  width: 440px;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 16px;
  height: 100%;
`;

const FormFieldWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  height: calc(100% - 36px - 44px - 16px);
  overflow-y: auto;
`;

const ButtonWrapper = styled.div`
  display: flex;
  gap: 16px;
`;

const Button = styled.button<{ color?: string }>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 44px;
  border-radius: 6px;
  font-size: 16px;
  font-weight: 600;
  color: ${({ color }) => (color === 'blue' ? '#ffffff' : '#3A3B3E')};
  background-color: ${({ color }) =>
    color === 'blue' ? '#246CF6' : '#e5e6e8'};

  &:disabled {
    background-color: #cbccce;
  }
`;

const CloseButton = styled.button`
  position: absolute;
  top: 24px;
  right: 24px;
  width: 24px;
  height: 24px;
`;

const Title = styled.h3`
  font-size: 18px;
  font-weight: 700;
  color: #000000;
  text-align: center;
  margin-bottom: 16px;
`;

const InquiryHistoryContainer = styled.div`
  width: calc(100% - 440px);
  min-width: 480px;
`;

const HistoryInput = styled.form`
  display: flex;
  gap: 12px;
  height: 152px;
`;

const TextArea = styled.textarea`
  width: calc(100% - 80px);
  height: 100%;
  padding: 20px;
  border: 1px solid #e5e6e8;
  border-radius: 10px;
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  color: #030303;
  resize: none;

  ::placeholder {
    color: #a7a7a7;
  }
`;

const HistoryButton = styled.button<{ disabled: boolean }>`
  width: 80px;
  height: 100%;
  border-radius: 10px;
  font-size: 16px;
  font-weight: 600;
  color: #ffffff;
  background-color: ${({ disabled }) => (disabled ? '#CBCCCE' : '#246cf6')};
`;

const Container = styled.div`
  width: 100%;
  height: calc(100% - 152px - 12px - 36px);
  margin-bottom: 12px;
  padding: 20px;
  border-radius: 10px;
  border: 1px solid #e5e6e8;
  background-color: #ffffff;
`;

const InquiryList = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 24px 20px;
  margin: 0;
  height: 100%;
  background-color: #f5f6f7;
  border-radius: 6px;
  overflow-y: auto;
`;

const InquiryItem = styled.li`
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: 14px 16px;
  width: 100%;
  background-color: #ffffff;
  border: 1px solid #e5e6e8;
  border-radius: 8px;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Content = styled.div`
  display: flex;
  gap: 12px;
`;

const Text = styled.p`
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  color: #030303;
  white-space: pre-line;
`;

const InquiryHistory = styled.strong`
  font-size: 14px;
  font-weight: 600;
  color: #3a3b3e;
`;

const DeleteButton = styled.button`
  cursor: pointer;
`;

export default ModalContent;
