import React, { ChangeEvent, useState } from 'react';
import styled, { css } from 'styled-components';
import ActionBtn from '@/components/common/ActionBtn';
import { formatHospitalFeedbackDetailInfo } from '@/utils/medical';
import FlexWrap from '@/components/common/FlexWrap';
import SalesSearch from './SalesSearch';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { addFeedback, editFeedback } from '@/api/medical';
import { getSalesStoreName } from '@/api/sales';

function FeedbackContent({ data, hospitalId }: any) {
  const formattedHospitalInfo = formatHospitalFeedbackDetailInfo(data);
  const [isEditMode, setIsEditMode] = useState(false);
  const [inputData, setInputData] = useState({
    feedbackId: hospitalId,
    salesStore: data.salesStoreName,
    feedbackDueDate: data?.feedback?.feedbackDueDate || '',
    memo: data?.feedback?.memo || '',
  });

  const queryClient = useQueryClient();

  const { mutate: mutateAdd } = useMutation(addFeedback, {
    onSuccess: () => {
      alert('수정되었습니다.');
      setIsEditMode(false);
      queryClient.invalidateQueries('feedbackList');
      queryClient.invalidateQueries('hospitalDetail');
    },
  });

  const { mutate: mutateEdit } = useMutation(editFeedback, {
    onSuccess: () => {
      alert('수정되었습니다.');
      setIsEditMode(false);
      queryClient.invalidateQueries('feedbackList');
      queryClient.invalidateQueries('hospitalDetail');
    },
  });

  const onEdit = () => {
    if (isEditMode) {
      const [salesId, salesStoreName] = inputData.salesStore
        ? inputData.salesStore.split('. ').map((item: string) => item.trim())
        : [null, ''];

      const salesIdNumber = Number(salesId);
      const salesStoreNameString = salesStoreName;

      if (data.feedback) {
        mutateEdit({
          feedbackId: data.feedback.feedbackId,
          salesId: salesStoreNameString ? salesIdNumber : null,
          salesStoreName: salesStoreNameString,
          feedbackDueDate: inputData.feedbackDueDate,
          memo: inputData.memo,
        });
      } else {
        mutateAdd({
          storeId: hospitalId,
          memo: inputData.memo,
          feedbackDueDate: inputData.feedbackDueDate,
          salesId: salesStoreNameString ? salesIdNumber : null,
          salesStoreName: salesStoreNameString,
        });
      }
    } else {
      setIsEditMode(true);
    }
  };

  const onChangeInput = (e: ChangeEvent<HTMLInputElement>, name: string) => {
    const { value } = e.target;
    setInputData({
      ...inputData,
      [name]: value,
    });
  };

  const { data: keywords } = useQuery(['salesStoreName'], getSalesStoreName, {
    onSuccess: (result) => {
      if (data?.salesStoreName) {
        const regex = new RegExp(data?.salesStoreName, 'i');
        const salesStores = result.filter((item: any) =>
          regex.test(item.keyword),
        );
        if (salesStores.length === 1) {
          setInputData({ ...inputData, salesStore: salesStores[0].keyword });
        } else {
          setInputData({ ...inputData, salesStore: '' });
        }
      }
    },
  });

  return (
    <Container>
      <Title>미조치 가맹점 현황</Title>
      <SubTitle>가맹점 정보</SubTitle>
      <StyledTable width='100%' align='center'>
        {data &&
          formattedHospitalInfo.map((items, tr) => (
            <StyledTr key={tr}>
              {items.map((item, td) => (
                <>
                  <StyledTh>{item.label}</StyledTh>
                  <StyledTd disabled>
                    <FlexWrap alignItems='center'>
                      <FlexWrap flex='1'>
                        {tr === 0 && td === 1 ? (
                          <SalesSearch
                            value={inputData.salesStore}
                            onChange={(val: string) =>
                              setInputData({
                                ...inputData,
                                salesStore: val,
                              })
                            }
                            isEditMode={isEditMode}
                            data={keywords}
                          />
                        ) : (
                          <StyledInput disabled {...item.option} />
                        )}
                      </FlexWrap>
                    </FlexWrap>
                  </StyledTd>
                </>
              ))}
            </StyledTr>
          ))}
      </StyledTable>
      <SubTitle>조치 내역</SubTitle>
      <StyledTable width='100%' align='center'>
        <StyledTr>
          <StyledTh>조치 만료일 및 조치 내역</StyledTh>
          <StyledTd disabled={!isEditMode} isEditMode={isEditMode}>
            <FlexWrap alignItems='center'>
              <FlexWrap flex='1'>
                <StyledInput
                  disabled={!isEditMode}
                  type='date'
                  value={inputData.feedbackDueDate}
                  onChange={(e) => onChangeInput(e, 'feedbackDueDate')}
                />
              </FlexWrap>
            </FlexWrap>
          </StyledTd>
          <StyledTd
            style={{ width: '50%' }}
            disabled={!isEditMode}
            isEditMode={isEditMode}
          >
            <FlexWrap alignItems='center'>
              <FlexWrap flex='1'>
                <StyledInput
                  disabled={!isEditMode}
                  value={inputData.memo}
                  onChange={(e) => onChangeInput(e, 'memo')}
                  placeholder='조치 내역을 입력해주세요.'
                />
              </FlexWrap>
            </FlexWrap>
          </StyledTd>
        </StyledTr>
      </StyledTable>
      <BtnWrapper>
        <ActionBtn
          title={isEditMode ? '수정완료' : '수정하기'}
          onClick={onEdit}
          bgColor='#354EFF'
        />
      </BtnWrapper>
    </Container>
  );
}
const Container = styled.div`
  width: 100%;
`;
const Title = styled.h2`
  font-size: 28px;
  margin-bottom: 40px;
`;
const SubTitle = styled.h3`
  font-size: 18px;
  margin-bottom: 16px;
`;
const StyledInput = styled.input`
  color: black;
  border: none;
  outline: none;
  width: 100%;
  padding: 8px 0;
  -moz-appearance: textfield;
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  ${(props) =>
    props.disabled &&
    css`
      background-color: #f2f2f2;
    `}
`;
const borderStyles = css`
  border-collapse: collapse;
  border: 1px solid black;
`;
const StyledTable = styled.table<{ align?: string }>`
  ${borderStyles}
  margin: 0 0 40px;
`;
const StyledTd = styled.td<{ disabled?: boolean; isEditMode?: boolean }>`
  ${borderStyles}
  padding: 0 16px;
  height: 40px;
  font-size: 14px;
  background-color: ${(props) =>
    props.isEditMode ? (props.disabled ? '#f2f2f2' : 'white') : '#f2f2f2'};
  width: 350px;
`;
const StyledTh = styled.td`
  ${borderStyles}
  padding-left: 16px;
  height: 40px;
  font-size: 14px;
  line-height: 40px;
  background-color: #d3d3d3;
  width: 190px;
  color: black;
`;

const StyledTr = styled.tr`
  ${borderStyles}
  padding: 8px 15px;
  font-size: 14px;
`;
const BtnWrapper = styled.div`
  width: 100%;
  text-align: right;
`;
const ResetButtonContainer = styled(FlexWrap)``;
const ResetButton = styled.button`
  width: 60px;
  padding: 5px 9px;
  font-size: 14px;
  line-height: 22px;
  font-weight: 500;
  color: #354eff;
  border: 2px solid #354eff;
  border-radius: 4px;
`;
export default FeedbackContent;
