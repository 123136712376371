import styled from 'styled-components';
import { TableRow } from '@material-ui/core';
import CustomTableCell from '@/components/common/CustomTableCell';
import DetailBtn from '@/components/common/DetailBtn';
import { useCallback } from 'react';
import { format } from 'date-fns';

function ManagementItem({
  idx,
  data,
  onOpen,
  onOpenDetailModal,
  onOpenFeedbackModal,
}: any) {
  const {
    franchiseeId,
    createdDate,
    storeName,
    lastRefundDate,
    feedbackStatus,
    feedbackDueDate,
    businessNumber,
  } = data;

  const onOpenRefund = useCallback(() => {
    onOpen('REFUND', {
      franchiseeIndex: franchiseeId,
      storeName,
    });
  }, [onOpen, storeName, franchiseeId]);

  const onOpenDetail = useCallback(() => {
    onOpenDetailModal('DETAIL', {
      franchiseeIndex: franchiseeId,
    });
  }, [onOpenDetailModal, franchiseeId]);

  const onOpenFeedback = useCallback(() => {
    onOpenFeedbackModal('FEEDBACK', {
      franchiseeId,
    });
  }, [onOpenFeedbackModal, franchiseeId]);

  return (
    <StyledTableRow
      onClick={(e: any) => {
        if (e.target.tagName === 'BUTTON') return;
        onOpenFeedback();
      }}
    >
      <CustomTableCell isCenter>{idx}</CustomTableCell>
      <CustomTableCell>
        {feedbackStatus === 'FEEDBACK_IN_PROGRESS' ? '조치' : '미조치'}
      </CustomTableCell>
      <CustomTableCell>
        {format(new Date(createdDate), 'yyyy-MM-dd')}
      </CustomTableCell>
      <CustomTableCell>{storeName}</CustomTableCell>
      <CustomTableCell>{businessNumber}</CustomTableCell>
      <CustomTableCell>
        {format(new Date(lastRefundDate), 'yyyy-MM-dd')}
      </CustomTableCell>
      <CustomTableCell color={!!feedbackDueDate ? '#ED0828' : ''}>
        {feedbackDueDate || '-'}
      </CustomTableCell>
      <CustomTableCell>
        <DetailBtn onClick={onOpenRefund}>상세보기</DetailBtn>
      </CustomTableCell>
      <CustomTableCell>
        <DetailBtn onClick={onOpenDetail}>상세보기</DetailBtn>
      </CustomTableCell>
    </StyledTableRow>
  );
}

const StyledTableRow = styled(TableRow)`
  cursor: pointer;
  background-color: #ffffff;
  :hover {
    background-color: #f7f9fc;
  }
`;

export default ManagementItem;
