import styled from 'styled-components';
import { TableRow } from '@material-ui/core';

import CustomTableCell from '@/components/common/CustomTableCell';
import Typography from '@/components/common/Typography';
import { BillType, KioskDetailDepositInfo } from '@/types/api/kiosk';
import DeleteModal from '@/components/common/Modal/DeleteModal';

import useModal from '@/hooks/common/useModal';
import { useKioskTransactionDelete } from '@/hooks/kiosk/useKioskTransactionDelete';

type DataListProps = {
  data: KioskDetailDepositInfo;
  idx: number;
  billType: BillType;
};

function CashItem({ data, idx, billType }: DataListProps) {
  const {
    isOpenModal: isOpenDeleteModal,
    openModal: openDeleteModal,
    closeModal: closeDeleteModal,
  } = useModal();

  const { mutate: deleteMutation } = useKioskTransactionDelete();
  const handleRefundDelete = () => {
    if (data.kioskTransactionId) {
      deleteMutation(data.kioskTransactionId, {
        onSuccess: () => {
          closeDeleteModal();
        },
      });
    }
  };

  const { bd1, bd2, bd3, hp1 } = billType;

  return (
    <StyledTableRow idx={idx}>
      <CustomTableCell idx={0} width='120' fontSize='14px' fontWeight={500}>
        {data.createdDate.slice(0, 10)}
      </CustomTableCell>
      <CustomTableCell width='90' fontSize='14px' fontWeight={500}>
        {data.managerName}
      </CustomTableCell>
      <CustomTableCell
        width='120'
        fontSize='14px'
        fontWeight={500}
        color={bd1 ? '#80848A' : '#CBCCCE'}
      >
        {data.bd1Count > 0 && '+'}
        {data.bd1Count}
        <Typography>{` (${data.bd1TotalCount})`}</Typography>
      </CustomTableCell>
      <CustomTableCell
        width='120'
        fontSize='14px'
        fontWeight={500}
        color={bd2 ? '#80848A' : '#CBCCCE'}
      >
        {data.bd2Count > 0 && '+'}
        {data.bd2Count}
        <Typography>{` (${data.bd2TotalCount})`}</Typography>
      </CustomTableCell>
      <CustomTableCell
        width='120'
        fontSize='14px'
        fontWeight={500}
        color={bd3 ? '#80848A' : '#CBCCCE'}
      >
        {data.bd3Count > 0 && '+'}
        {data.bd3Count}
        <Typography>{` (${data.bd3TotalCount})`}</Typography>
      </CustomTableCell>
      <CustomTableCell
        width='120'
        fontSize='14px'
        fontWeight={500}
        color={hp1 ? '#80848A' : '#CBCCCE'}
      >
        {data.hp1Count > 0 && '+'}
        {data.hp1Count}
        <Typography>{` (${data.hp1TotalCount})`}</Typography>
      </CustomTableCell>
      <CustomTableCell width='40'>
        <DeleteButton onClick={openDeleteModal}>삭제</DeleteButton>
      </CustomTableCell>
      {isOpenDeleteModal && (
        <DeleteModal
          title='충전 건 삭제'
          desc={`삭제를 원하실 경우 취소 코드를 입력해주세요.\n삭제된 충전내역은 복구할 수 없습니다.`}
          isOpen={isOpenDeleteModal}
          onCancel={closeDeleteModal}
          onDelete={handleRefundDelete}
        />
      )}
    </StyledTableRow>
  );
}

const StyledTableRow = styled(TableRow)<{ idx: number }>`
  background-color: #ffffff;
  border-bottom: ${(props) =>
    (props.idx + 1) % 5 === 0
      ? '2px solid rgb(220, 220, 220)'
      : '1px solid rgb(220, 220, 220)'};
  :hover {
    background-color: #f7f9fc;
  }
  span {
    font-weight: 500;
  }
`;

const DeleteButton = styled.button`
  padding: 6px 16px;
  background-color: #ffffff;
  color: #cbccce;
  font-size: 14px;
  line-height: 150%;
  font-weight: 600;
  border-radius: 8px;
  border: 1px solid #cbccce;
  cursor: pointer;
`;

export default CashItem;
