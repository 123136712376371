import { comma } from '@/utils/ktp';
import { TableBody, TableCell, TableRow } from '@material-ui/core';
import React from 'react';

function TotalRefundList({
  totalData: {
    totalCount,
    totalCancelCount,
    totalPrice,
    totalRefund,
    totalActualAmount,
  },
}) {
  return (
    <TableBody>
      <TableRow>
        <TableCell style={{ padding: 8, fontSize: 13 }} align='center'>
          {comma(totalCount)}건
        </TableCell>
        <TableCell style={{ padding: 8, fontSize: 13 }} align='center'>
          {comma(totalCancelCount)}건
        </TableCell>
        <TableCell style={{ padding: 8, fontSize: 13 }} align='center'>
          {comma(totalActualAmount)}원
        </TableCell>
        <TableCell style={{ padding: 8, fontSize: 13 }} align='center'>
          {comma(totalRefund)}원
        </TableCell>
        <TableCell style={{ padding: 8, fontSize: 13 }} align='center'>
          {comma(totalPrice)}원
        </TableCell>
      </TableRow>
    </TableBody>
  );
}

export default TotalRefundList;
