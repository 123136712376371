export const homeColumnList = {
  refund: [
    { type: 'refundIndex', title: 'No' },
    { type: 'createdDate', title: '환급일자' },
    { type: 'storeName', title: '상호명' },
    { type: 'customerNational', title: '국적' },
    { type: 'customerName', title: '고객명' },
    { type: 'totalAmount', title: '판매금액' },
    { type: 'totalRefund ', title: '환급액' },
    { type: 'refundStatus', title: '환급상태' },
    { type: 'departureStatus', title: '출국상태' },
    { type: 'paymentStatus', title: '송금상태' },
    { type: 'detail', title: '상세보기' },
  ],
  kiosk: [
    { type: 'kioskIndex', title: 'No' },
    { type: 'hospitalName', title: '상호명' },
    { type: 'tenThounsandAccount', title: '만원 잔량' },
    { type: 'thounsandAccount', title: '천원 잔량' },
    { type: 'fivehundredAccount', title: '오백원 잔량' },
    { type: 'kioskStatus', title: '키오스크 상태' },
  ],
  notice: [
    { type: 'noticeIndex', title: 'No' },
    { type: 'title', title: '제목' },
    { type: 'content', title: '내용' },
    { type: 'scheduledDate', title: '발행 일자', isCenter: true },
    { type: 'detail', title: '상세보기' },
  ],
};

export const signUpTableData = [
  [
    {
      label: '사업자등록번호',
      option: {
        name: 'businessNumber',
        placeholder: "'-' 표시 없이 숫자만 입력",
      },
      required: true,
    },
    {
      label: '비밀번호',
      option: {
        name: 'password',
        type: 'password',
        placeholder: '비밀번호 4자리 이상',
      },
      required: true,
    },
  ],
  [
    {
      label: '상호명',
      option: { name: 'storeName', type: 'text' },
      required: true,
    },
    {
      label: '대표자명',
      option: { name: 'sellerName', type: 'text' },
      required: true,
    },
  ],
  [
    {
      label: '간판명',
      option: { name: 'signboard', type: 'text', placeholder: '(선택 사항)' },
    },
    {
      label: '이메일',
      option: {
        name: 'email',
        type: 'email',
        placeholder: '_______ @ ________',
      },
      required: true,
    },
  ],
  [
    {
      label: '휴대 전화번호',
      option: {
        name: 'storeTel',
        maxLength: 11,
        placeholder: "'-' 표시 없이 숫자만 입력",
      },
      required: true,
    },
    {
      label: '매장 전화번호',
      option: {
        name: 'storeNumber',
        placeholder: "'-' 표시 없이 숫자만 입력",
      },
      required: true,
    },
  ],
  [
    {
      label: '사업장 주소',
      option: { name: 'storeAddressBasic', type: 'text' },
      required: true,
    },
    {
      label: '상세 주소',
      option: { name: 'storeAddressDetail', type: 'text' },
      required: true,
    },
  ],
  [
    {
      label: '사후면세판매장 가입여부',
      option: {
        name: 'isTaxRefundShop',
        type: 'text',
        placeholder: 'Y / N',
      },
      required: true,
    },
    {
      label: '판매 상품 종목',
      selectName: 'productCategory',
      optionList: [
        '판매상품 종목 선택',
        '의류',
        '신발',
        '잡화',
        '화장품',
        '가전기기',
        '전기 • 전자기기',
        '특산품',
        '호텔 • 숙박',
        '의약품',
        '기타',
      ],
      required: true,
    },
  ],
  [
    {
      label: '사업자 분류',
      selectName: 'businessType',
      optionList: [
        { label: '사업자 분류 선택', value: '사업자 분류 선택' },
        { label: '법인', value: 'CORPORATE' },
        { label: '개인', value: 'INDIVIDUAL' },
      ],
      required: true,
    },
    {
      label: '추천인 코드',
      option: { name: 'recommender', type: 'text', placeholder: '(선택 사항)' },
    },
  ],
  [
    {
      label: '종사업자번호',
      option: {
        name: 'subCompanySerial',
        type: 'text',
        placeholder: '종사업자번호',
      },
    },
    {
      label: '',
      option: {},
    },
  ],
];
export const applicationTableData = [
  [
    {
      label: '사후면세판매장 지정번호',
      option: { name: 'taxFreeStoreNumber', type: 'text' },
    },
    {
      label: '은행명',
      selectName: 'bankName',
      optionList: [
        '은행 선택',
        '농협',
        '신한은행',
        '기업은행',
        '하나은행',
        '우리은행',
        '국민은행',
        'SC제일은행',
        '대구은행',
        '부산은행',
        '광주은행',
        '새마을금고',
        '경남은행',
        '전북은행',
        '제주은행',
        '산업은행',
        '우체국',
        '신협',
        '수협',
        '한국씨티은행',
        '키카오뱅크',
        '토스뱅크',
        '대신증권',
        '메리츠증권',
        '미래에셋증권',
        '삼성증권',
        '신한금융투자',
        'NH 투자증권',
        '저축은행',
        '한국투자증권',
        'SK증권',
      ],
    },
  ],
  [
    { label: '계좌번호', option: { name: 'accountNumber', type: 'text' } },
    {
      label: '자동이체 출금일',
      selectName: 'withdrawalDate',
      optionList: [
        '출금일 선택',
        '5일',
        '10일',
        '15일',
        '20일',
        '25일',
        '30일',
      ],
    },
  ],
];

export const globalSignUpTableData = [
  [
    {
      label: '사업자등록번호',
      option: {
        name: 'businessNumber',
        type: 'number',
        maxLength: 10,
        placeholder: "'-' 표시 없이 숫자만 입력",
      },
    },
    {
      label: '비밀번호',
      option: {
        name: 'password',
        type: 'password',
        placeholder: '비밀번호 4자리 이상',
      },
    },
  ],
  [
    { label: '상호명', option: { name: 'storeName', type: 'text' } },
    { label: '대표자명', option: { name: 'sellerName', type: 'text' } },
  ],
  [
    {
      label: '휴대 전화번호',
      option: {
        name: 'storeTel',
        type: 'number',
        maxLength: 11,
        placeholder: "'-' 표시 없이 숫자만 입력",
      },
    },
    {
      label: '매장 전화번호',
      option: {
        name: 'storeNumber',
        type: 'number',
        placeholder: "'-' 표시 없이 숫자만 입력",
      },
    },
  ],
  [
    {
      label: '이메일',
      option: {
        name: 'email',
        type: 'email',
        placeholder: '_______ @ ________',
      },
    },
    {
      label: '사업자 분류',
      selectName: 'businessType',
      optionList: [
        { label: '사업자 분류 선택', value: '사업자 분류 선택' },
        { label: '법인', value: 'CORPORATE' },
        { label: '개인', value: 'INDIVIDUAL' },
      ],
    },
  ],
  [
    {
      label: '사업장 주소',
      option: { name: 'storeAddressBasic', type: 'text' },
    },
    {
      label: '상세 주소',
      option: { name: 'storeAddressDetail', type: 'text' },
    },
  ],
];
export const mainMenuArr = [
  'KTP 매장',
  'KTP 의료',
  '키오스크',
  '세일즈',
  // 'KTP카드',
  'Q&A',
];
export const subMenuArr = [
  [
    {
      title: '운영',
      path: '/ktp/prod/home',
    },
    {
      title: '테스트',
      path: '/ktp/test/home',
    },
    {
      title: 'Refund',
      path: '/developer/refund',
    },
  ],
  [
    {
      title: '운영',
      path: '/refund/medical/prod/home',
    },
    {
      title: '테스트',
      path: '/refund/medical/test/home',
    },
  ],
  [
    {
      title: '운영',
      path: '/kiosk/prod/home',
    },
    {
      title: '테스트',
      path: '/kiosk/test/home',
    },
  ],
  [
    {
      title: '운영',
      path: '/sales/prod/history',
    },
    {
      title: '테스트',
      path: '/sales/test/history',
    },
  ],
  // [
  //   {
  //     title: '운영',
  //     path: '/card/prod/home',
  //   },
  //   {
  //     title: '테스트',
  //     path: '/card/test/home',
  //   },
  // ],
  [
    {
      title: '운영',
      path: '/qna/prod/ktp',
    },
    {
      title: '테스트',
      path: '/qna/test/ktp',
    },
  ],
];
export const franchiseeStatusData = {
  isReadRadio: [
    { value: 'all', label: '전체' },
    { value: false, label: 'New' },
  ],
  franchiseeStatusRadio: [
    { value: 'all', label: '전체' },
    { value: 'INIT', label: '미신청' },
    { value: 'WAIT', label: '대기' },
    { value: 'ACCEPTED', label: '승인' },
    { value: 'REJECTED', label: '거절' },
    { value: 'REAPPLIED', label: '재신청' },
  ],
  columns: [
    { toggle: true, type: 'isRead', title: '알림' },
    { toggle: false, type: 'bussinessApplicantIndex', title: 'No' },
    { toggle: true, type: 'franchiseeStatus', title: '가맹점 신청상태' },
    { toggle: false, type: 'createdDate', title: '회원가입 날짜' },
    { toggle: false, type: 'businessNumber', title: '사업자등록번호' },
    { toggle: false, type: 'sellerName', title: '이름' },
    { toggle: false, type: 'storeName', title: '상호명' },
    { toggle: false, type: 'appVersion', title: '앱 버전' },
    { toggle: false, type: 'isRefundOnce', title: '환급 현황' },
    { toggle: false, type: 'detail', title: '상세보기' },
    { toggle: false, type: 'etc', title: '계정 용도' },
  ],
};
export const refundStatusData = {
  allColumns: [
    { toggle: false, type: 'refundIndex', title: 'No' },
    { toggle: false, type: 'createdDate', title: '환급일자' },
    { toggle: false, type: 'storeName', title: '상호명' },
    { toggle: false, type: 'customerNational', title: '국적' },
    { toggle: false, type: 'customerName', title: '고객명' },
    { toggle: false, type: 'customerAge', title: '고객 연령' },
    { toggle: false, type: 'customerGender', title: '고객 성별' },
    { toggle: false, type: 'totalAmount', title: '판매금액' },
    { toggle: false, type: 'totalRefund ', title: '환급액' },
    { toggle: true, type: 'refundStatus', title: '환급상태' },
    { toggle: false, type: 'departureStatus', title: '출국상태' },
    { toggle: false, type: 'paymentStatus', title: '송금상태' },
    { toggle: false, type: 'detail', title: '상세보기' },
    { toggle: false, type: 'cancelRefund', title: '환급취소' },
  ],
  immediateColumns: [
    { toggle: false, type: 'refundIndex', title: 'No' },
    { toggle: false, type: 'createdDate', title: '환급일자' },
    { toggle: false, type: 'storeName', title: '상호명' },
    { toggle: false, type: 'customerNational', title: '국적' },
    { toggle: false, type: 'customerName', title: '고객명' },
    { toggle: false, type: 'customerAge', title: '고객 연령' },
    { toggle: false, type: 'customerGender', title: '고객 성별' },
    { toggle: false, type: 'totalAmount', title: '판매금액' },
    { toggle: false, type: 'totalRefund ', title: '환급액' },
    { toggle: true, type: 'refundStatus', title: '환급상태' },
    { toggle: false, type: 'detail', title: '상세보기' },
    { toggle: true, type: 'cancelRefund', title: '환급취소' },
  ],
  postColumns: [
    { toggle: false, type: 'refundIndex', title: 'No' },
    { toggle: false, type: 'createdDate', title: '환급일자' },
    { toggle: false, type: 'storeName', title: '상호명' },
    { toggle: false, type: 'customerNational', title: '국적' },
    { toggle: false, type: 'customerName', title: '고객명' },
    { toggle: false, type: 'customerAge', title: '고객 연령' },
    { toggle: false, type: 'customerGender', title: '고객 성별' },
    { toggle: false, type: 'totalAmount', title: '판매금액' },
    { toggle: false, type: 'totalRefund ', title: '환급액' },
    { toggle: false, type: 'refundStatus', title: '환급상태' },
    { toggle: true, type: 'departureStatus', title: '출국상태' },
    { toggle: true, type: 'paymentStatus', title: '송금상태' },
    { toggle: true, type: 'refundUploadS3Path', title: '이미지 업로드' },
    { toggle: false, type: 'detail', title: '상세보기' },
    { toggle: false, type: 'cancelRefund', title: '환급취소' },
  ],
  refundStatusRadio: [
    { value: 'ALL', label: '전체' },
    { value: 'APPROVAL', label: '승인' },
    { value: 'PRE_APPROVAL', label: '승인 대기' },
    { value: 'CANCEL', label: '취소' },
    { value: 'CHECK', label: '선별검사' },
    { value: 'KIOSK_CANCEL', label: '키오스크 취소' },
  ],
  paymentStatusRadio: [
    { value: 'ALL', label: '전체' },
    { value: 'PAYMENT_WAIT', label: '송금 미완료' },
    { value: 'PAYMENT_COMPLETE', label: '송금 완료' },
  ],
  departureStatusRadio: [
    {
      value: 'ALL',
      label: '전체',
    },
    {
      value: 'DEPARTURE_WAIT',
      label: '출국 미확인',
    },
    {
      value: 'DEPARTURE_COMPLETE',
      label: '출국 확인',
    },
  ],
  uploadImageRadio: [
    { value: 'ALL', label: '전체' },
    { value: 'UPLOAD_COMPLETE', label: '업로드' },
    { value: 'UPLOAD_NOT_COMPLETE', label: '미업로드' },
  ],
};
export const pointWithdrawalData = {
  columns: [
    { toggle: true, type: 'isAll', title: '알림' },
    { toggle: false, type: 'pointsIndex', title: 'No' },
    { toggle: true, type: 'withdrawalStatus', title: '정산 상태' },
    { toggle: false, type: 'requestedDate', title: '출금신청 날짜' },
    { toggle: false, type: 'businessNumber', title: '사업자등록번호' },
    { toggle: false, type: 'sellerName', title: '대표자명' },
    { toggle: false, type: 'storeName', title: '상호명' },
    { toggle: false, type: 'amount', title: '출금요청 포인트' },
    { toggle: false, type: 'detail', title: '상세보기' },
  ],
  isAllRadio: [
    { value: true, label: '전체' },
    { value: false, label: 'New' },
  ],
  pointStatusRadio: [
    { value: 'ALL', label: '전체' },
    { value: 'WITHDRAW', label: '정산 대기' },
    { value: 'COMPLETE', label: '정산 완료' },
  ],
};
export const pushNotificationData = {
  columns: [
    { toggle: false, type: 'pushIndex', title: 'No' },
    { toggle: false, type: 'title', title: '알림 메세지 제목' },
    { toggle: false, type: 'createdDate', title: '발송시기' },
    { toggle: false, type: 'detail', title: '상세보기' },
  ],
};
export const NoticeData = {
  columns: [
    { type: 'noticeIndex', title: 'No' },
    { type: 'title', title: '공지사항 제목' },
    { type: 'createdDate', title: '작성 날짜' },
    { type: 'scheduledDate', title: '예약공지 시기' },
    { type: 'isFixed', title: '상단 고정' },
    { type: 'isInvisible', title: '노출 여부' },
    { type: 'detail', title: '상세보기' },
  ],
};
export const remittanceStatusData = {
  columns: [
    { toggle: false, type: 'modifyNeeded', title: '' },
    { toggle: false, type: 'selectBox', title: '' },
    { toggle: false, type: 'remittanceIndex', title: 'No' },
    { toggle: false, type: 'refundDate', title: '환급일자' },
    { toggle: false, type: 'storeName', title: '상호명' },
    { toggle: false, type: 'customerNational', title: '국적' },
    { toggle: false, type: 'sellerName', title: '고객명' },
    { toggle: false, type: 'totalRefund', title: '환급액' },
    { toggle: false, type: 'remittanceInfoDate', title: '정보 입력' },
    { toggle: false, type: 'departureDate', title: '출국 예정일' },
    { toggle: false, type: 'departureStatus', title: '출국 상태' },
    { toggle: false, type: 'isImageUpload', title: '이미지' },
    { toggle: false, type: 'remittanceStatus', title: '송금 상태' },
  ],
};
export const sortFilterAll = [
  { title: '어드민 넘버 (기본)', isSelected: true, value: 'DEFAULT' },
  {
    title: '송금 완료 최신순',
    isSelected: false,
    value: 'TRANSFER_COMPLETE_DATE',
  },
  {
    title: '정보 입력 최신순',
    isSelected: false,
    value: 'INFO_TYPED_DATE',
  },
];
export const sortFilterPayment = [
  { title: '어드민 넘버 (기본)', isSelected: true, value: 'DEFAULT' },
  { title: '정보 입력 최신순', isSelected: false, value: 'INFO_TYPED_DATE' },
];
export const paymentFilter = [
  { title: '송금 미완료 (기본)', isSelected: true, value: '' },
  { title: '송금중', isSelected: false, value: 'PAYMENT_IN_PROGRESS' },
  { title: '송금 완료', isSelected: false, value: 'PAYMENT_COMPLETE' },
  { title: '전체', isSelected: false, value: 'ALL' },
];
export const mailCountFilter = [
  { title: '전체', isSelected: true, value: '' },
  { title: '3회', isSelected: false, value: '3' },
  { title: '2회', isSelected: false, value: '2' },
  { title: '1회', isSelected: false, value: '1' },
];

export const kioskStatusData = {
  columns: [
    { toggle: false, type: 'kioskIndex', title: 'No' },
    { toggle: false, type: 'storeName', title: '상호명' },
    { toggle: false, type: 'bd2Count', title: '만원 잔량' },
    { toggle: false, type: 'bd1Count', title: '천원 잔량' },
    { toggle: false, type: 'hp1Count', title: '오백원 잔량' },
    { toggle: false, type: 'kioskStatus', title: '키오스크 상태' },
  ],
};

export const QNA_TABLE_DATA = {
  columns: [
    { toggle: false, type: 'isRead', title: '' },
    { toggle: false, type: 'qnaIndex', title: 'No' },
    { toggle: false, type: 'createDate', title: '문의일시' },
    { toggle: false, type: 'nation', title: '국적' },
    { toggle: false, type: 'name', title: '고객명' },
    { toggle: false, type: 'content', title: '문의 내용' },
  ],
};

export const QNA_TABLE_DATA_NOTANSWERED = {
  columns: [
    { toggle: false, type: 'isRead', title: '' },
    { toggle: false, type: 'checkbox', title: '' },
    { toggle: false, type: 'qnaIndex', title: 'No' },
    { toggle: false, type: 'createDate', title: '문의일시' },
    { toggle: false, type: 'nation', title: '국적' },
    { toggle: false, type: 'name', title: '고객명' },
    { toggle: false, type: 'content', title: '문의 내용' },
  ],
};

export const managementStatusData = {
  columns: [
    { toggle: false, type: 'managementIndex', title: 'No' },
    { toggle: false, type: 'managementStatus', title: '상태' },
    { toggle: false, type: 'joinDate', title: '가입일' },
    { toggle: false, type: 'storeName', title: '상호명' },
    { toggle: false, type: 'businessNumber', title: '사업자 등록번호' },
    { toggle: false, type: 'recentRefundDate', title: '최종 환급일' },
    { toggle: false, type: 'actionExpireDate', title: '조치 만료일' },
    { toggle: false, type: 'refundButton', title: '환급 현황' },
    { toggle: false, type: 'detailButton', title: '상세보기' },
  ],
};
