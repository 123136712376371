import Address from './Address';
import DatePicker from './DatePicker';
import Dropdown from './Dropdown';
import Field from './Field';
import Image from './Image';
import TextInput from './TextInput';

export const FormField = Object.assign(Field, {
  TextInput,
  Dropdown,
  DatePicker,
  Address,
  Image,
});
