import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { TableRow } from '@material-ui/core';

import CustomTableCell from '@/components/common/CustomTableCell';
import { GetKioskStatusContent } from '@/types/api/kiosk';
import { determineKioskStatus, formatBillType } from '@/utils/kiosk';
import store from '@/store';

type KioskListProps = {
  data: GetKioskStatusContent;
  idx: number;
  size: number;
  number: number;
  totalElements: number;
};

function KioskList({ data, idx, size, number, totalElements }: KioskListProps) {
  const history = useHistory();
  const serverState = store.getState().serverState;
  const {
    bd1Count,
    bd2Count,
    bd3Count,
    hp1Count,
    name,
    kioskStatus,
    billType,
    kioskId,
    sustainableDay,
  } = data;
  const { bd1, bd2, bd3, hp1 } = billType;
  // index 구하기 => 전체 요소 - 한페이지 보여지는 수 - 현재페이지 - 현재페이지 인덱스
  const indexNumber = totalElements - size * number - idx;
  const error = determineKioskStatus(billType, kioskStatus);
  const onClickKioskStatus = () => {
    history.push({
      pathname: `/kiosk/${serverState}/home/detail`,
      state: {
        kioskId,
        preTitle: '키오스크 상태',
        title: name,
        billType,
      },
    });
  };

  return (
    <StyledTableRow idx={idx} onClick={() => onClickKioskStatus()}>
      <CustomTableCell idx={0} width='50'>
        {indexNumber < 10 ? String(indexNumber).padStart(2, '0') : indexNumber}
      </CustomTableCell>
      <CustomTableCell width='180'>{name}</CustomTableCell>
      <CustomTableCell
        width='90'
        color={kioskStatus.bd1Lack ? '#FF2D55' : bd1 ? '#212121' : '#CBCCCE'}
      >
        {bd1Count}/2000
        {bd1 ? `(${formatBillType(bd1)})` : ''}
      </CustomTableCell>
      <CustomTableCell
        width='90'
        color={kioskStatus.bd2Lack ? '#FF2D55' : bd2 ? '#212121' : '#CBCCCE'}
      >
        {bd2Count}/2000
        {bd2 ? `(${formatBillType(bd2)})` : ''}
      </CustomTableCell>
      <CustomTableCell
        width='90'
        color={kioskStatus.bd3Lack ? '#FF2D55' : bd3 ? '#212121' : '#CBCCCE'}
      >
        {bd3Count}/2000
        {bd3 ? `(${formatBillType(bd3)})` : ''}
      </CustomTableCell>
      <CustomTableCell
        width='90'
        color={kioskStatus.hp1Lack ? '#FF2D55' : hp1 ? '#212121' : '#CBCCCE'}
      >
        {hp1Count}/300
        {hp1 ? '(오백원)' : ''}
      </CustomTableCell>
      <CustomTableCell
        width='90'
        color={determineKioskStatus(billType, kioskStatus).color}
      >
        {error.cashAlert}
        {error.cashAlert && error.message && '/'}
        {error.message}
      </CustomTableCell>
      <CustomTableCell
        width='60'
        color={sustainableDay <= 14 ? '#FF2D55' : '#212121'}
      >
        {sustainableDay !== null ? `${sustainableDay}일` : ''}
      </CustomTableCell>
    </StyledTableRow>
  );
}

const StyledTableRow = styled(TableRow)<{ idx: number }>`
  background-color: #ffffff;
  border-bottom: ${(props) =>
    (props.idx + 1) % 5 === 0
      ? '2px solid rgb(220, 220, 220)'
      : '1px solid rgb(220, 220, 220)'};
  :hover {
    background-color: #f7f9fc;
  }
`;

export default KioskList;
